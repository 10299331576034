const DownloadTest = () => {
  const fileUrl = "https://54.177.8.212/linked-data/TestHTTPS_download.xlsx";

  return (
    <div>
      <a href={fileUrl} download>
        Download Test File
      </a>
    </div>
  );
};

export default DownloadTest;
