import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import styled from "styled-components/macro";

const FormWrapper = styled("div")`
  margin: 16px 0 8px 0;
`;

const ParameterSelect = ({ availableParameters, addParameter }) => {
  return (
    <FormWrapper>
      <Select
        fullWidth
        variant="outlined"
        labelId="parameter-label"
        id="parameter-select"
        value=""
        onChange={(e) => addParameter(e.target.value)}
        displayEmpty
      >
        <MenuItem value="" disabled>
          Select Parameter to Add Min/Max Values to Query
        </MenuItem>
        {availableParameters.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormWrapper>
  );
};

export default ParameterSelect;
