import { useQuery } from "react-query";
import {
  hasEmptyValues,
  matchesArrayField,
  matchesFiscalYear,
  matchesMultiField,
  matchesParameterRange,
  matchesStatus,
} from "./utils";
import { useCallback, useMemo, useState } from "react";
import { useAxiosWithAuth } from "../../reports/signalFireTelemetry/hooks/useAxiosWithAuth";
import {
  BASE_URL,
  DEFAULT_INPUT_STATE,
  PARAMETERS_OPTIONS,
  USE_QUERY_OPTIONS,
} from "./constants";

export const useSearchPage = () => {
  const fetchWithAuth = useAxiosWithAuth(BASE_URL);

  const [inputState, setInputState] = useState(DEFAULT_INPUT_STATE);
  const [availableParameters, setAvailableParameters] =
    useState(PARAMETERS_OPTIONS);

  const addParameter = useCallback((param) => {
    const paramDetails = PARAMETERS_OPTIONS.find(
      ({ value }) => value === param
    );
    setInputState((prev) => ({
      ...prev,
      parameters: [
        ...prev.parameters,
        {
          key: param,
          label: paramDetails.label,
          type: paramDetails.type,
          min: "", // values for type range
          max: "",
          value: [], // value for type multiselect
        },
      ],
    }));
    setAvailableParameters((prev) => prev.filter((p) => p.value !== param));
  }, []);

  const removeParameter = useCallback((paramKey) => {
    setInputState((prev) => ({
      ...prev,
      parameters: prev.parameters.filter((p) => p.key !== paramKey),
    }));
    setAvailableParameters((prev) => [
      ...prev,
      PARAMETERS_OPTIONS.find((p) => p.value === paramKey),
    ]);
  }, []);

  const updateParameterValue = useCallback((index, field, value) => {
    // If the incoming value is from an event, value.target.value; otherwise, value directly.
    const newValue = value.target ? value.target.value : value;

    setInputState((prev) => ({
      ...prev,
      parameters: prev.parameters.map((p, i) => {
        if (i === index) {
          // Check if the field should handle multiple values
          if (field === "value") {
            return {
              ...p,
              [field]: Array.isArray(newValue) ? newValue : [newValue],
            };
          }
          // Single value fields like 'min' or 'max'
          return { ...p, [field]: newValue };
        }
        return p;
      }),
    }));
  }, []);

  const handleInputState = useCallback((key, value) => {
    setInputState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleReset = useCallback(() => setInputState(DEFAULT_INPUT_STATE), []);

  const fiscalYearsQuery = useQuery(
    "wq-list-fiscal-years",
    () => fetchWithAuth("wq-list-fiscal-years", {}),
    USE_QUERY_OPTIONS
  );
  const wellUsesQuery = useQuery(
    "wq-list-well-uses",
    () => fetchWithAuth("wq-list-well-uses"),
    {
      ...USE_QUERY_OPTIONS,
      onSuccess: (data) =>
        handleInputState(
          "wellUses",
          data.map((item) => item.use_ndx)
        ),
    }
  );
  const aquifersQuery = useQuery(
    "wq-list-aquifers",
    () => fetchWithAuth("wq-list-aquifers"),
    {
      ...USE_QUERY_OPTIONS,
      onSuccess: (data) =>
        handleInputState(
          "aquifers",
          data.map((item) => item.aquifer_ndx)
        ),
    }
  );
  const managementZonesQuery = useQuery(
    "wq-list-management-zones",
    () => fetchWithAuth("wq-list-management-zones"),
    {
      ...USE_QUERY_OPTIONS,
      onSuccess: (data) =>
        handleInputState(
          "managementZones",
          data.map((item) => item.management_zone)
        ),
    }
  );
  const wellStatusesQuery = useQuery(
    "wq-list-well-status",
    () => fetchWithAuth("wq-list-well-status"),
    USE_QUERY_OPTIONS
  );
  const coliformQuery = useQuery(
    "wq-list-coliform-options",
    () => fetchWithAuth("wq-list-coliform-options", {}),
    USE_QUERY_OPTIONS
  );
  const ecoliQuery = useQuery(
    "wq-list-ecoli-options",
    () => fetchWithAuth("wq-list-ecoli-options", {}),
    USE_QUERY_OPTIONS
  );
  const allDataQuery = useQuery(
    "wq-data-all",
    () => fetchWithAuth("wq-data-all", {}),
    USE_QUERY_OPTIONS
  );

  const inputs = useMemo(
    () => ({
      fiscalYears: {
        data: fiscalYearsQuery.data || [],
        isLoading: fiscalYearsQuery.isFetching,
        error: fiscalYearsQuery.error,
      },
      aquifers: {
        data: aquifersQuery.data || [],
        isLoading: aquifersQuery.isFetching,
        error: aquifersQuery.error,
      },
      managementZones: {
        data: managementZonesQuery.data || [],
        isLoading: managementZonesQuery.isFetching,
        error: managementZonesQuery.error,
      },
      wellStatuses: {
        data: wellStatusesQuery.data || [],
        isLoading: wellStatusesQuery.isFetching,
        error: wellStatusesQuery.error,
      },
      wellUses: {
        data: wellUsesQuery.data || [],
        isLoading: wellUsesQuery.isFetching,
        error: wellUsesQuery.error,
      },
      parameters: {
        data: PARAMETERS_OPTIONS,
        isLoading: false,
        error: false,
      },
      coliform: {
        data: coliformQuery.data || [],
        isLoading: coliformQuery.isFetching,
        error: coliformQuery.error,
      },
      ecoli: {
        data: ecoliQuery.data || [],
        isLoading: ecoliQuery.isFetching,
        error: ecoliQuery.error,
      },
    }),
    [
      fiscalYearsQuery,
      aquifersQuery,
      managementZonesQuery,
      wellStatusesQuery,
      wellUsesQuery,
      coliformQuery,
      ecoliQuery,
    ]
  );

  const isLoadingAny = useMemo(
    () =>
      Object.values(inputs).some(({ isLoading }) => isLoading) ||
      allDataQuery.isFetching,
    [inputs, allDataQuery.isFetching]
  );

  const { isFormValid, currentCount } = useMemo(() => {
    const isValid =
      !isLoadingAny && !hasEmptyValues(inputState, ["parameters"]);
    let count = null;

    if (isValid && allDataQuery?.data?.length) {
      count = allDataQuery.data.filter((record) => {
        return (
          // Checks basic required conditions
          matchesFiscalYear(record, inputState.startYear, inputState.endYear) &&
          matchesStatus(record, "is_exempt", inputState.isExempt) &&
          matchesStatus(record, "is_active", inputState.isActive) &&
          matchesMultiField(record.assoc_well_use_ndx, inputState.wellUses) &&
          matchesArrayField(
            record.management_zone,
            inputState.managementZones
          ) &&
          matchesArrayField(record.aquifer_ndx, inputState.aquifers) &&
          matchesArrayField(record.well_status_ndx, inputState.wellStatuses) &&
          // Checks optional parameters: type range and multiselect
          inputState.parameters.every((param) => {
            if (param.type === "multiselect") {
              return matchesArrayField(record[param.key], param.value);
            } else {
              return matchesParameterRange(
                record,
                param.key,
                param.min,
                param.max
              );
            }
          })
        );
      }).length;
    }

    const finalIsValid = isValid && count > 0;

    return { isFormValid: finalIsValid, currentCount: count };
  }, [isLoadingAny, inputState, allDataQuery.data]);

  const handleCreateReport = () => {
    const baseUrl = "/data-access/tools/water-quality-records-search-results";
    const params = new URLSearchParams();

    // Set regular required inputs
    Object.entries(inputState).forEach(([key, value]) => {
      if (![null, undefined].includes(value) && key !== "parameters") {
        params.set(key, value);
      }
    });

    // Handle optional parameters
    inputState.parameters.forEach((param) => {
      if (param.type === "multiselect" && param.value.length > 0) {
        params.set(param.key, param.value);
      } else if (param.type !== "multiselect") {
        const hasMin = param.min !== "" && param.min !== undefined;
        const hasMax = param.max !== "" && param.max !== undefined;
        if (hasMin) {
          params.set(`${param.key}:min`, param.min);
        }
        if (hasMax) {
          params.set(`${param.key}:max`, param.max);
        }
        if (!hasMin && !hasMax) {
          params.set(`${param.key}:exists`, true);
        }
      } else {
        params.set(`${param.key}:exists`, true);
      }
    });

    const reportUrl = `${
      window.location.origin
    }${baseUrl}?${params.toString()}`;
    window.open(reportUrl, "_blank");
  };

  return {
    inputs,
    currentCount,
    inputState,
    handleInputState,
    isFormValid,
    handleCreateReport,
    handleReset,
    addParameter,
    removeParameter,
    updateParameterValue,
    availableParameters,
  };
};

export default useSearchPage;
