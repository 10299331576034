import React from "react";
import { withWidth } from "@material-ui/core";
import EditFormFuzzySearchCurrentRolodex from "./EditFormFuzzySearchCurrentRolodex";
import CreateNewSubForm from "./CreateNewSubForm";
function EditFormOwnerAndContact(props) {
  const [creatingNew, setCreatingNew] = React.useState(false);

  return (
    <>
      <CreateNewSubForm
        {...props}
        creatingNew={creatingNew}
        setCreatingNew={setCreatingNew}
      />

      {!creatingNew && <EditFormFuzzySearchCurrentRolodex {...props} />}
    </>
  );
}

export default withWidth()(EditFormOwnerAndContact);
