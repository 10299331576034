import React, { useState, useCallback } from "react";
import { DatePicker } from "@material-ui/pickers";

const DateRangeFilter = ({ columnDef, onFilterChanged }) => {
  const [filter, setFilter] = useState({ startDate: null, endDate: null });

  const handleDateChange = useCallback(
    (date, name) => {
      const newFilter = { ...filter, [name]: date ? date.toISOString() : null };
      setFilter(newFilter);
      onFilterChanged(columnDef.tableData.id, newFilter);
    },
    [filter, onFilterChanged, columnDef.tableData.id]
  );

  return (
    <div>
      <DatePicker
        label="Start Date"
        value={filter.startDate}
        onChange={(date) => handleDateChange(date, "startDate")}
      />
      <DatePicker
        label="End Date"
        value={filter.endDate}
        onChange={(date) => handleDateChange(date, "endDate")}
      />
    </div>
  );
};

export default DateRangeFilter;
