import React, { useState } from "react";
import { Grid, FormControlLabel, Button } from "@material-ui/core";
import useFetchData from "../../../hooks/useFetchData";

import styled from "styled-components/macro";
import MaterialTable, { MTableBodyRow } from "material-table";
import { Switch } from "@lrewater/lre-react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../AppProvider";
import { getButtonIcon, getButtonText } from "../../../utils";

const Root = styled.div`
  margin-top: 6px;
  width: 100%;
`;

const StyledMTableBodyRow = styled(MTableBodyRow)`
  &:hover {
    background-color: #fff3df !important;
  }
`;

const WellsToOwnersAssoc = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const [activeWell, setActiveWell] = useState({});
  const [activeOwner, setActiveOwner] = useState(null);
  const [exclude, setExclude] = useState(true);
  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [Wells, isWellsLoading] = useFetchData("wells", [refreshSwitch], true);
  const [Owners, isContactsLoading] = useFetchData(
    "ui-owners-for-wells-view",
    [],
    true
  );

  const handleRefresh = () => {
    setRefreshSwitch((state) => !state);
  };

  const handleExclude = () => {
    setExclude((state) => !state);
  };

  const handleRowClick = (event, rowData) => {
    setActiveWell(
      rowData.well_ndx === setActiveWell?.well_ndx ? null : rowData
    );
    setActiveOwner(rowData?.owner_rolo_ndx ?? null);
  };

  const handleCheck = (rowData, row) => {
    if (row.rolo_ndx === activeOwner) {
      setActiveOwner(null);
    } else {
      setActiveOwner(row.rolo_ndx);
    }
  };

  const handleSubmit = () => {
    let didCancel = false;
    async function updateData() {
      try {
        setIsFormSubmitting(true);

        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        await axios.patch(
          `${process.env.REACT_APP_ENDPOINT}/api/wells/owner/${activeWell.well_ndx}`,
          { owner_rolo_ndx: activeOwner },
          { headers }
        );
        if (!didCancel) {
          setIsFormSubmitting(false);
          doToast("success", "Association successfully saved.");
          console.log("success");
          handleRefresh();
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
        doToast("error", "Association could not be saved");
        didCancel = true;
      }
    }
    updateData();
  };

  const filterData = (data) => {
    if (exclude) {
      return data.filter((item) => activeOwner === item.rolo_ndx);
    }
    return data;
  };

  const Toolbar = (props) => {
    const { handleChange, checked } = props;
    return (
      <div
        style={{
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              label=""
              checked={checked}
              value="exclude"
              name="exclude"
              onChange={handleChange}
            />
          }
          label={
            checked ? (
              <>
                Toggle to view <em>all</em> owners
              </>
            ) : (
              <>
                Toggle to view only the <em>associated</em> owner
              </>
            )
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeWell || !activeOwner || isFormSubmitting}
          startIcon={getButtonIcon(isFormSubmitting)}
          style={{ width: "180px" }}
        >
          {getButtonText(isFormSubmitting)} Owner
        </Button>
      </div>
    );
  };

  const wellsColumns = [
    {
      title: "CUWCD Well Number",
      field: "cuwcd_well_number",
      customFilterAndSearch: (searchValue, rowData) => {
        const { cuwcd_well_number, state_well_number, well_name } = rowData;
        const searchLowercase = searchValue.toLowerCase();

        return (
          (cuwcd_well_number &&
            cuwcd_well_number.toLowerCase().includes(searchLowercase)) ||
          (state_well_number &&
            state_well_number.toLowerCase().includes(searchLowercase)) ||
          (well_name && well_name.toLowerCase().includes(searchLowercase))
        );
      },
      render: (rowData) => (
        <Grid container direction="column">
          <Grid item>
            <strong>{rowData.cuwcd_well_number}</strong>
          </Grid>
          <Grid item>
            <em>{rowData.state_well_number}</em>
          </Grid>
          <Grid item>{rowData.well_name}</Grid>
        </Grid>
      ),
    },
  ];

  const ownersColumns = [
    {
      title: "Last Name",
      field: "lastname",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "First Name",
      field: "firstname",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "Organization",
      field: "organization",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "City",
      field: "city",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "Email",
      field: "email_1",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
    },
  ];

  return (
    <Root>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6} lg={5} xl={4}>
          <MaterialTable
            isLoading={isWellsLoading}
            id="Wells"
            columns={wellsColumns}
            data={Wells}
            options={{
              emptyRowsWhenPaging: false,
              addRowPosition: "first",
              pageSize: 30,
              pageSizeOptions: [5, 10, 30, 60],
              searchFieldAlignment: "left",
              showTitle: false,
              maxBodyHeight:
                "calc(100vh - 64px - 48px - 106px - 48px - 64px - 213px)",
              rowStyle: (rowData, i) => ({
                backgroundColor:
                  activeWell?.well_ndx === rowData.well_ndx
                    ? "#fff3df" // Light yellow for selected row
                    : i % 2 === 0
                    ? "#f6f6f6" // Light grey for alternate rows
                    : "inherit",
              }),
            }}
            components={{
              Row: (props) => <StyledMTableBodyRow {...props} hover={true} />,
            }}
            onRowClick={handleRowClick}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={7} xl={8}>
          <MaterialTable
            isLoading={isContactsLoading}
            id="Owners"
            columns={ownersColumns}
            data={filterData(Owners)}
            options={{
              emptyRowsWhenPaging: false,
              addRowPosition: "first",
              pageSize: 30,
              pageSizeOptions: [5, 10, 30, 60],
              searchFieldAlignment: "left",
              showTitle: false,
              maxBodyHeight:
                "calc(100vh - 64px - 48px - 106px - 48px - 64px - 213px)",
              rowStyle: (rowData, i) => ({
                backgroundColor:
                  i % 2 === 0
                    ? "#f6f6f6" // Light grey for alternate rows
                    : "inherit",
              }),
              selectionProps: (rowData) => ({
                checked: rowData.rolo_ndx === activeOwner,
              }),
              selection: true,
              search: false,
              showTextRowsSelected: false,
              showSelectAllCheckbox: false,
              filtering: true,
            }}
            components={{
              Toolbar: (props) => {
                return (
                  <Toolbar
                    handleChange={handleExclude}
                    checked={exclude}
                    {...props}
                  />
                );
              },
            }}
            onSelectionChange={handleCheck}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export default WellsToOwnersAssoc;
