import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, Grid, Box, Chip } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchData from "../../../hooks/useFetchData";
import useFormSubmitStatus from "../../../hooks/useFormSubmitStatus";
import { Flex } from "../../../components/Flex";

import FormSnackbar from "../../../components/FormSnackbar";

import SearchableList from "../../../components/SearchableList";

import styled from "styled-components/macro";
import AssociationControls from "./AssociationControls";
import { useQuery } from "react-query";
import { useApp } from "../../../AppProvider";
import PermitsListForLoginsManagementTable from "./PermitsListForLoginsManagementTable";
import Loader from "../../../components/Loader";

const Root = styled.div`
  margin-top: 6px;
  width: 100%;
`;

const LoginsToPermitsAssoc = () => {
  const { currentUser } = useApp();
  const { getAccessTokenSilently } = useAuth0();
  const { setWaitingState, snackbarOpen, snackbarError, handleSnackbarClose } =
    useFormSubmitStatus();

  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const [associations, setAssociations] = useState([]);
  const [activeLogin, setActiveLogin] = useState({});

  const [Permits, isLoading] = useFetchData(`ui-permits-for-users-view`, []);

  const { data: Logins } = useQuery(
    ["users", currentUser, isLoading, refreshSwitch],
    async () => {
      if (!currentUser || isLoading) return [];
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users/associations/${currentUser.isDeveloper}`,
          { headers }
        );

        const dataWithPermitNames = [];

        data.forEach((item) => {
          dataWithPermitNames.push({
            ...item,
            ...{
              associated_permits_names: item.associated_permits.map(
                (permitNdx) =>
                  Permits.find((p) => p.permit_ndx === permitNdx)?.permit_number
              ),
            },
          });
        });

        return dataWithPermitNames;
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const activeAssociations = activeLogin?.associated_permits ?? [];
    if (activeAssociations.length > 0 && activeAssociations[0] !== null) {
      setAssociations(activeAssociations);
    } else {
      setAssociations([]);
    }
  }, [activeLogin]);

  const handleSelectNone = () => setAssociations([]);

  const handleSelectAll = () =>
    setAssociations(Permits.map((d) => d.permit_ndx));

  const handleRefresh = () => {
    setRefreshSwitch((state) => !state);
  };

  const handleLoginSelect = (login) => {
    setActiveLogin(login);
  };

  const handlePermitsSelect = (rowData, row) => {
    const value = row.permit_ndx;
    const checked = row.tableData.checked;
    setAssociations((prevState) => {
      let newValues = [...prevState];
      if (checked) {
        newValues.push(+value);
      } else {
        const index = newValues.indexOf(+value);
        newValues.splice(index, 1);
      }
      return newValues;
    });
  };

  const handleSubmit = () => {
    let didCancel = false;
    setWaitingState("in progress");
    async function writeData() {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users/associations`,
          { id: activeLogin.auth0_user_id, permits: associations },
          { headers }
        );

        if (!didCancel) {
          console.log("success");
          setWaitingState("complete", "no error");
          handleRefresh();
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
          setWaitingState("complete", "error");
        }
        didCancel = true;
      }
    }
    writeData();
  };

  return (
    <Root>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          {Logins ? (
            <SearchableList
              title="Logins"
              data={Logins}
              valueField="auth0_user_id"
              primaryDisplayField="auth0_name"
              secondaryDisplayField="auth0_email"
              tooltipDisplayField="associated_permits_names"
              active={activeLogin}
              onClick={handleLoginSelect}
            />
          ) : (
            <Loader />
          )}
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box marginTop={2} width="100%">
            <Flex>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Manage Permits Associations for:
              </Typography>

              {activeLogin.auth0_name && (
                <Box marginTop={2} marginBottom={2} marginLeft={2}>
                  <Chip
                    label={`${activeLogin.auth0_name} / ${activeLogin.auth0_email}`}
                  />
                </Box>
              )}
            </Flex>

            {activeLogin.auth0_name && (
              <AssociationControls
                handleSave={handleSubmit}
                handleSelectAll={handleSelectAll}
                handleSelectNone={handleSelectNone}
              />
            )}

            {activeLogin.auth0_name ? (
              <PermitsListForLoginsManagementTable
                data={Permits}
                selections={associations}
                onCheck={handlePermitsSelect}
                isLoading={isLoading}
              />
            ) : (
              <>
                <Typography variant="body1" paragraph>
                  Select a login from the left to associate them with permits.
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <FormSnackbar
        open={snackbarOpen}
        error={snackbarError}
        handleClose={handleSnackbarClose}
        successMessage="Associations successfully saved."
        errorMessage="Associations could not be saved."
      />
    </Root>
  );
};

export default LoginsToPermitsAssoc;
