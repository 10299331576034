import React from "react";
import styled from "styled-components/macro";

import { Box, Button, Grid as MuiGrid, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { spacing } from "@material-ui/system";
import { ROUTES } from "../../../constants";
import Link from "@material-ui/core/Link";
import { useAuth0 } from "@auth0/auth0-react";

const StyledButtonYellow = styled(Button)`
  background-color: #fcce14;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e7ba08;
  }
`;

const StyledButtonWhite = styled(Button)`
  background-color: #fff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #fcf8f8;
  }
`;

const Grid = styled(MuiGrid)(spacing);

function JoinUs() {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <div style={{ borderTop: "10px solid #1D62AF" }} />
      <Box style={{ margin: "5px 10px 10px 10px" }}>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} lg={4}>
            <Box height={460} border={1} borderColor="grey.500">
              <Box
                border={1}
                borderColor="grey.300"
                height="66.66%"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                flexDirection="column"
                style={{
                  backgroundImage: `url(/static/img/state-texas-hero.jpg)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                }}
              >
                <StyledButtonYellow
                  variant="contained"
                  href="https://www.drought.gov/states/texas"
                  target="_blank"
                  rel="noreferrer noopener"
                  size="large"
                >
                  Texas Drought Maps
                </StyledButtonYellow>
                <StyledButtonYellow
                  size="large"
                  variant="contained"
                  href="https://www.drought.gov/states/texas/county/bell"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Bell County Drought Maps
                </StyledButtonYellow>
              </Box>

              <Box
                height="16.66%"
                bgcolor="white"
                display="flex"
                alignItems="center"
                padding={2}
              >
                <Link
                  href="https://www.drought.gov"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src="/static/img/NOAA-NIDIS-logo.png"
                    alt="NOAA-NIDIS Logo"
                    style={{
                      marginRight: "10px",
                      height: "100%",
                    }}
                  />
                  <div>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "1.4em" }}
                    >
                      Texas
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ color: "lightgray", fontSize: "1.2em" }}
                    >
                      Drought.gov
                    </Typography>
                  </div>
                </Link>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="16.66%"
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  href="https://dashboard.waterdata.usgs.gov/app/nwd/en/?aoi=usgs-08104300"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  USGS Water Dashboard Salado Ck at Salado, TX
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <iframe
              src="https://dashboard.waterdata.usgs.gov/api/gwis/2.1/service/site?agencyCode=USGS&siteNumber=08104300&open=140845"
              style={{ border: "2px solid black" }}
              width="100%"
              // height="675"
              height="460"
              title="USGS Graph"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              border={1}
              borderColor="grey.500"
              height={460}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center"
              style={{
                backgroundImage: `url(/static/img/interactive-map.JPG)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                  marginBottom: "18px",
                }}
              >
                <StyledButtonWhite
                  variant="contained"
                  size="large"
                  onClick={() =>
                    loginWithRedirect({
                      appState: { returnTo: ROUTES.PAGE_PRODUCTION },
                    })
                  }
                >
                  Well Owners Enter Production Data
                </StyledButtonWhite>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  component={RouterLink}
                  to={ROUTES.PUBLIC_MAP}
                >
                  Clearwater Wells Public Map
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default JoinUs;
