import { addDays, subDays } from "date-fns";

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api/signal-fire-telemetry`;

export const DEFAULT_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
};

const START_DAYS_AGO = 30;

export const DEFAULT_START_DATE = subDays(new Date(), START_DAYS_AGO);

export const DEFAULT_END_DATE = new Date();

export const DATE_FORMAT = "MMM dd yyyy";

export const GEOLOGIC_FORMATIONS_CONFIG = {
  top_austin: { color: "#94CE94", position: "90%", label: "Austin" },
  top_delrio: { color: "#E8BA6C", position: "80%", label: "Del Rio" },
  top_edwards: { color: "#C5FBC7", position: "70%", label: "Edwards" },
  top_walnut: { color: "#097608", position: "60%", label: "Walnut" },
  top_glen_rose: { color: "#99DEF5", position: "50%", label: "Glen Rose" },
  top_hensell: { color: "#C454C4", position: "40%", label: "Hensell" },
  top_pearsall: { color: "#DFE094", position: "30%", label: "Pearsall" },
  top_hosston: { color: "#403ECD", position: "20%", label: "Hosston" },
};

export const SCREENING_INTERVALS_CONFIG = {
  screen_top_depth_ft: {
    color: "black",
    position: "start",
    label: "Top Depth",
    yAdjust: -19,
    borderDash: [6, 6],
  },
  screen_bottom_depth_ft: {
    color: "black",
    position: "end",
    label: "Bottom Depth",
    yAdjust: 19,
    borderDash: [6, 6],
  },
};

const FIRST_OF_YEAR = new Date(new Date().getFullYear(), 0, 1);

export const QUICKSET_OPTIONS = [
  {
    label: "Year to Date",
    calculateStartDate: () => FIRST_OF_YEAR,
  },
  {
    label: "Last 7 Days",
    calculateStartDate: () => addDays(new Date(), -7),
  },
  {
    label: "Last 30 Days",
    calculateStartDate: () => addDays(new Date(), -30),
  },
  {
    label: "Last 90 Days",
    calculateStartDate: () => addDays(new Date(), -90),
  },
  {
    label: "Last 180 Days",
    calculateStartDate: () => addDays(new Date(), -180),
  },
  {
    label: "Last 365 Days",
    calculateStartDate: () => addDays(new Date(), -365),
  },
];

export const OMIT_FIELDS_FOR_CSV = [
  "well_ndx",
  "screen_bottom_depth_ft",
  "screen_top_depth_ft",
];

export const FIELD_ORDER_FOR_CSV = [
  "well_name",
  "cuwcd_well_number",
  "state_well_number",
  "collected_date",
  "avg_dtw_ft",
  "aquifer_name",
  "management_zone",
  "texas_grid_id",
  "elevation_ftabmsl",
  "well_depth_ft",
  "well_owner",
  "date_drilled",
  "notes",
  "latitude_dd",
  "longitude_dd",
];
