import { useMemo } from "react";
import { setColorOpacity } from "../utils";

const restoreOriginalColors = (dataset) => {
  dataset.backgroundColor = dataset.originalBackgroundColor;
  dataset.borderColor = dataset.originalBorderColor;
};

const setHoverColors = (dataset, isHovered) => {
  const opacity = isHovered ? 0.9 : 0.25;
  const borderColor = isHovered ? "#000" : dataset.originalBackgroundColor;

  dataset.backgroundColor = setColorOpacity(
    dataset.originalBackgroundColor,
    opacity
  );
  dataset.borderColor = setColorOpacity(borderColor, opacity);
};

const adjustStylesOnHover = (chart, hoveredDatasetIndex) => {
  chart.data.datasets.forEach((dataset, index) => {
    setHoverColors(dataset, index === hoveredDatasetIndex);
  });
  chart.update();
};

const restoreOriginalStyles = (chart) => {
  chart.data.datasets.forEach((dataset) => {
    restoreOriginalColors(dataset);
  });
  chart.update();
};

export const useChartOptions = (yLLabel, annotations) => {
  return useMemo(
    () => ({
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: "point",
      },
      plugins: {
        tooltip: {
          enabled: true,
          mode: "index",
        },
        legend: {
          onHover: (evt, hoveredItem, legend) =>
            !hoveredItem.hidden &&
            adjustStylesOnHover(legend.chart, hoveredItem.datasetIndex),
          onLeave: (evt, hoveredItem, legend) =>
            !hoveredItem.hidden && restoreOriginalStyles(legend.chart),
          onClick: null,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy",
            modifierKey: "ctrl",
          },
          zoom: {
            mode: "xy",
            drag: {
              enabled: true,
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 1,
              backgroundColor: "rgba(54, 162, 235, 0.3)",
              drawTime: "afterDatasetsDraw",
            },
          },
        },
        annotation: {
          common: {
            drawTime: "afterDraw",
          },
          annotations: {
            ...annotations,
          },
        },
      },
      onHover: (event, chartElements) => {
        const chart = event.chart;
        if (chartElements.length) {
          const hoveredElement = chartElements[0];
          adjustStylesOnHover(chart, hoveredElement.datasetIndex);
        } else {
          restoreOriginalStyles(chart);
        }
      },

      scales: {
        x: {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "MMM DD YYYY",
            },
            tooltipFormat: "MMM DD YYYY",
          },
          grid: {
            display: false,
          },
        },
        yL: {
          reverse: true,
          title: {
            display: true,
            text: yLLabel,
          },
          grid: {
            borderDash: [10, 5],
            drawBorder: true,
            color: "#cbcbcb",
          },
        },
      },
    }),
    [yLLabel, annotations]
  );
};
