import React, { useCallback, useState } from "react";

const NumericRangeFilter = ({ columnDef, onFilterChanged }) => {
  const [range, setRange] = useState({ min: "", max: "" });

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      const newRange = { ...range, [name]: value };
      setRange(newRange);
      onFilterChanged(columnDef.tableData.id, newRange);
    },
    [range, onFilterChanged, columnDef.tableData.id]
  );

  return (
    <div>
      <input
        type="number"
        name="min"
        value={range.min}
        onChange={handleChange}
        placeholder="Min"
      />
      <input
        type="number"
        name="max"
        value={range.max}
        onChange={handleChange}
        placeholder="Max"
      />
    </div>
  );
};

export default NumericRangeFilter;
