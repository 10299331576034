import { useLocation } from "react-router-dom";
import { useAxiosWithAuth } from "../../reports/signalFireTelemetry/hooks/useAxiosWithAuth";
import {
  BASE_URL,
  DATE_FORMAT,
  DEFAULT_CELL_STYLE,
  USE_QUERY_OPTIONS,
} from "./constants";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { IconButton } from "@material-ui/core";
import { PictureAsPdf } from "@material-ui/icons";
import {
  getLookupForDataSortedByField,
  parseQueryParams,
  transformTestDatetime,
} from "./utils";
import NumericRangeFilter from "./materialTableFeatures/NumericRangeFilter";
import moment from "moment";
import DateRangeFilter from "./materialTableFeatures/DateRangeFilter";
import { titleize } from "inflected";
import { dateFormatter } from "../../../../utils";

const useTableDataAndColumns = ({
  invert,
  rawTableData,
  defaultColumns,
  selectedRecords,
  renderPDFButton,
}) => {
  const formatTitle = useCallback((row, dateFormat = DATE_FORMAT) => {
    return `${row.cuwcd_well_number} on ${dateFormatter(
      new Date(row.test_datetime),
      dateFormat
    )}`;
  }, []);

  const filteredRows = useMemo(() => {
    return rawTableData?.filter((row) =>
      selectedRecords.includes(row.well_ndx)
    );
  }, [rawTableData, selectedRecords]);

  const tableData = useMemo(() => {
    if (!invert || !filteredRows.length) return rawTableData;

    const allKeys = defaultColumns.map((col) => col.field);
    return allKeys.map((key) => {
      return {
        property: key === "pdfActions" ? "View PDF" : titleize(key),
        ...filteredRows.reduce((acc, row) => {
          const formattedTitle = formatTitle(row);
          acc[formattedTitle] =
            key === "pdfActions"
              ? renderPDFButton(row)
              : key === "test_datetime"
              ? row.test_datetime
              : row[key] ?? "";
          return acc;
        }, {}),
      };
    });
  }, [
    invert,
    filteredRows,
    defaultColumns,
    renderPDFButton,
    formatTitle,
    rawTableData,
  ]);

  const columns = useMemo(() => {
    if (!invert) {
      return defaultColumns;
    }
    return [
      { title: "Property", field: "property", filtering: false },
      ...(filteredRows || []).map((row) => {
        const formattedTitle = formatTitle(row);
        return {
          title: formattedTitle,
          field: formattedTitle,
          filtering: false,
        };
      }),
    ];
  }, [invert, filteredRows, formatTitle, defaultColumns]);

  return { tableData, columns };
};

const useResultsPage = () => {
  const { search: rawSearchParams } = useLocation();
  const fetchWithAuth = useAxiosWithAuth(BASE_URL);

  const params = parseQueryParams(rawSearchParams);

  const tableRef = useRef(null);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [filteredWellNdx, setFilteredWellNdx] = useState([]);
  const [version, setVersion] = useState(0); // State to force re-render
  const [invert, setInvert] = useState(false);
  const [fullWidth, setFullWidth] = useState(false);

  const { data: rawTableData, isFetching: isLoadingTable } = useQuery(
    ["wq-data"],
    () => fetchWithAuth("wq-data", params),
    {
      ...USE_QUERY_OPTIONS,
      select: transformTestDatetime,
    }
  );

  const mapLocationIndexes =
    rawTableData?.map((record) => record.well_ndx) || [];
  const { data: mapData } = useQuery(
    "map",
    () => fetchWithAuth("map", { wells: mapLocationIndexes }),
    { ...USE_QUERY_OPTIONS, enabled: !!mapLocationIndexes.length }
  );

  const handleSelectPDF = useCallback((e, PDF) => {
    e.stopPropagation();
    setSelectedPDF(PDF);
  }, []);

  const handleRecordSelection = useCallback((ndx) => {
    setSelectedRecords((prev) =>
      prev.includes(ndx) ? prev.filter((id) => id !== ndx) : [...prev, ndx]
    );
  }, []);

  const handleClearRecordSelection = useCallback(
    () => setSelectedRecords([]),
    []
  );

  const handleFilterChange = useCallback(() => {
    if (tableRef?.current) {
      const newFilteredWellNdx = [
        ...new Set(
          tableRef.current.dataManager.filteredData.map(
            ({ well_ndx }) => well_ndx
          )
        ),
      ];
      setFilteredWellNdx(newFilteredWellNdx);
    }
  }, []);

  const handleToggleWidth = useCallback(() => {
    setFullWidth((prev) => !prev);
  }, []);

  const handleToggleInvert = useCallback(() => {
    setInvert((prev) => !prev);
  }, []);

  const clearFilters = useCallback(() => {
    if (invert) {
      handleClearRecordSelection();
      handleToggleInvert();
    }
    setVersion((current) => current + 1);
  }, [handleClearRecordSelection, handleToggleInvert, invert]);

  useEffect(() => {
    if (version) {
      handleFilterChange();
      handleClearRecordSelection();
    }
  }, [version, handleFilterChange, handleClearRecordSelection]);

  const lookup = useMemo(
    () => ({
      fiscalYear: getLookupForDataSortedByField(rawTableData, "fiscal_year"),
      managementZone: getLookupForDataSortedByField(
        rawTableData,
        "management_zone"
      ),
      aquiferName: getLookupForDataSortedByField(rawTableData, "aquifer_name"),
      coliform: getLookupForDataSortedByField(rawTableData, "coliform"),
      ecoli: getLookupForDataSortedByField(rawTableData, "ecoli"),
      collectedBy: getLookupForDataSortedByField(rawTableData, "collected_by"),
      testedBy: getLookupForDataSortedByField(rawTableData, "tested_by"),
    }),
    [rawTableData]
  );

  const testDatetimeFilter = useCallback((filter, rowData) => {
    const { startDate, endDate } = filter || {};
    const rowDate = moment(rowData.test_datetime).startOf("day");
    const start = startDate ? moment(startDate).startOf("day") : null;
    const end = endDate ? moment(endDate).startOf("day") : null;

    if (!start && !end) return true;
    if (start && !end) return rowDate.isSameOrAfter(start);
    if (!start && end) return rowDate.isSameOrBefore(end);
    return rowDate.isBetween(start, end, null, "[]");
  }, []);

  const filterComponents = useMemo(
    () => ({
      DateRangeFilter: (props) => <DateRangeFilter {...props} />,
      NumericRangeFilter: (props) => <NumericRangeFilter {...props} />,
    }),
    []
  );

  const renderPDFButton = useCallback(
    (rowData) => (
      <IconButton
        size="small"
        onClick={(e) => handleSelectPDF(e, rowData)}
        disabled={!rowData.cuwcd_well_number}
        aria-label="View PDF"
      >
        <PictureAsPdf />
      </IconButton>
    ),
    [handleSelectPDF]
  );

  const defaultColumns = useMemo(() => {
    const numericRangeFilter = (field) => (filter, rowData) => {
      const { min, max } = filter || {};
      const value = rowData[field];
      if (min === "" && max === "") return true;
      if (min !== "" && max === "") return value >= min && value !== null;
      if (min === "" && max !== "") return value <= max && value !== null;
      return value >= min && value <= max;
    };

    return [
      {
        title: "PDF",
        field: "pdfActions",
        sorting: false,
        filtering: false,
        render: renderPDFButton,
        cellStyle: {
          ...DEFAULT_CELL_STYLE,
          textAlign: "center",
        },
      },
      {
        title: "Test Date/Time",
        field: "test_datetime",
        type: "datetime",
        customFilterAndSearch: testDatetimeFilter,
        filterComponent: filterComponents.DateRangeFilter,
      },
      {
        title: "Fiscal Year",
        field: "fiscal_year",
        lookup: lookup.fiscalYear,
        type: "numeric",
      },
      {
        title: "Well Number",
        field: "cuwcd_well_number",
      },
      {
        title: "State Well ID",
        field: "state_well_number",
      },
      {
        title: "Well Name",
        field: "well_name",
      },
      {
        title: "Management Zone",
        field: "management_zone",
        lookup: lookup.managementZone,
      },
      {
        title: "Aquifer",
        field: "aquifer_name",
        lookup: lookup.aquiferName,
      },
      {
        title: "TX Grid",
        field: "texas_grid_id",
      },
      {
        title: "Owner",
        field: "well_owner",
      },
      {
        title: "Coliform",
        field: "coliform",
        lookup: lookup.coliform,
      },
      {
        title: "E. Coli",
        field: "ecoli",
        lookup: lookup.ecoli,
      },
      {
        title: "Conductivity, uS/cm",
        field: "conductivity",
        customFilterAndSearch: numericRangeFilter("conductivity"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "TDS, mg/L",
        field: "tds",
        customFilterAndSearch: numericRangeFilter("tds"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Salinity, mg/L",
        field: "salinity",
        customFilterAndSearch: numericRangeFilter("salinity"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "pH",
        field: "ph",
        customFilterAndSearch: numericRangeFilter("ph"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Sodium Ion, mg/L",
        field: "sodium_ion",
        customFilterAndSearch: numericRangeFilter("sodium_ion"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Alkalinity, mg/L CaCO3",
        field: "alkalinity",
        customFilterAndSearch: numericRangeFilter("alkalinity"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Hardness, mg/L CaCO3",
        field: "hardness",
        customFilterAndSearch: numericRangeFilter("hardness"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Nitrite, mg/L N",
        field: "nitrite",
        customFilterAndSearch: numericRangeFilter("nitrite"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Nitrate, mg/L N",
        field: "nitrate",
        customFilterAndSearch: numericRangeFilter("nitrate"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Phosphate, mg/L",
        field: "phosphate",
        customFilterAndSearch: numericRangeFilter("phosphate"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Sulfate, mg/L",
        field: "sulfate",
        customFilterAndSearch: numericRangeFilter("sulfate"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Fluoride, mg/L",
        field: "fluoride",
        customFilterAndSearch: numericRangeFilter("fluoride"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Chloride, mg/L",
        field: "chloride",
        customFilterAndSearch: numericRangeFilter("chloride"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Calcium, mg/L",
        field: "calcium",
        customFilterAndSearch: numericRangeFilter("calcium"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Magnesium, mg/L",
        field: "magnesium",
        customFilterAndSearch: numericRangeFilter("magnesium"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "CaCO3, mg/L CaCO3",
        field: "caco3",
        customFilterAndSearch: numericRangeFilter("caco3"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Comments",
        field: "comments",
      },
      {
        title: "Well Elev",
        field: "well_elev_ft",
        customFilterAndSearch: numericRangeFilter("well_elev_ft"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Well Depth, ft",
        field: "well_depth_ft",
        customFilterAndSearch: numericRangeFilter("well_depth_ft"),
        filterComponent: filterComponents.NumericRangeFilter,
        type: "numeric",
      },
      {
        title: "Collected By",
        field: "collected_by",
        lookup: lookup.collectedBy,
      },
      {
        title: "Tested By",
        field: "tested_by",
        lookup: lookup.testedBy,
      },
      {
        title: "Owner Phone",
        field: "owner_phone_number",
        filtering: false,
      },
      {
        title: "Owner Address",
        field: "owner_mailing_address",
      },
      {
        title: "Owner Email",
        field: "owner_email",
      },

      {
        title: "Longitude",
        field: "longitude_dd",
        type: "numeric",
        filtering: false,
      },
      {
        title: "Latitude",
        field: "latitude_dd",
        type: "numeric",
        filtering: false,
      },
    ];
  }, [renderPDFButton, testDatetimeFilter, lookup, filterComponents]);

  const { tableData, columns } = useTableDataAndColumns({
    invert,
    rawTableData,
    defaultColumns,
    selectedRecords,
    renderPDFButton,
  });

  useEffect(() => {
    if (rawTableData?.length && !invert) {
      const initialFilteredWellNdx = [
        ...new Set(rawTableData.map(({ well_ndx }) => well_ndx)),
      ];
      setFilteredWellNdx(initialFilteredWellNdx);
    }
  }, [rawTableData, invert]);

  return {
    mapData,
    selectedRecords,
    handleRecordSelection,
    handleSelectPDF,
    isLoadingTable,
    columns,
    selectedPDF,
    handleToggleWidth,
    fullWidth,
    tableData,
    handleToggleInvert,
    clearFilters,
    invert,
    filteredWellNdx,
    version,
    tableRef,
    handleFilterChange,
  };
};

export default useResultsPage;
