import React, { memo } from "react";
import {
  Box,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Loader from "../../../../../../components/Loader";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";

const SelectButtonGroup = styled(ButtonGroup)`
  height: 52px;
  .MuiButton-root {
    width: 80px;
  }
  margin: 16px 0 8px 0;
`;
const SelectButton = styled(Button)`
  &:first-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const SelectControl = ({
  label,
  id,
  valueField = "value",
  displayField = "label",
  value,
  onChange,
  options,
  isLoading,
  multiple = false,
  showSelectButtons = false,
}) => {
  const handleSelectAll = () => {
    onChange(
      id,
      options.map((option) => option[valueField])
    );
  };

  const handleDeselectAll = () => {
    onChange(id, []);
  };

  const selectStyles =
    showSelectButtons && multiple
      ? {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }
      : {};

  const isDisabled = options?.length === 0 || isLoading;

  return (
    <Box display="flex" flexDirection="row" width="100%" alignItems="start">
      <FormControl margin="normal" variant="outlined" fullWidth>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
              labelId={`${id}-label`}
              id={id}
              multiple={multiple}
              value={value}
              onChange={(e) => onChange(id, e.target.value)}
              label={label}
              style={selectStyles}
            >
              {options.map((option) => (
                <MenuItem key={option[valueField]} value={option[valueField]}>
                  {option[displayField]}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </FormControl>
      {showSelectButtons && multiple && (
        <SelectButtonGroup
          variant="outlined"
          color="primary"
          disabled={isDisabled}
        >
          <SelectButton variant="contained" onClick={handleSelectAll}>
            + All
          </SelectButton>
          <SelectButton onClick={handleDeselectAll}>- None</SelectButton>
        </SelectButtonGroup>
      )}
    </Box>
  );
};

export default memo(SelectControl);
