import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxDrawGeodesic from "mapbox-gl-draw-geodesic";
import { RulerControl } from "mapbox-gl-controls";
import styled, { ThemeProvider } from "styled-components/macro";
import { STARTING_LOCATION } from "../../constants";
import createTheme from "../../theme";
import { Accordion, AccordionDetails, Typography } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import debounce from "lodash.debounce";
import { create } from "jss";
import { jssPreset, StylesProvider } from "@material-ui/core/styles";
import DragCircleControl from "./DragCircleControl";
import {
  bellParcelsFill,
  bellParcelsLine,
  bellParcelsSymbol,
  DUMMY_BASEMAP_LAYERS,
  eagleView2024,
  handleCopyCoords,
  locationsLabelsLayer,
  locationsLayer,
  updateArea,
} from "../../utils/map";
import CoordinatesPopup from "./components/CoordinatesPopup";
import MeasurementsPopup from "./components/MeasurementsPopup";
import { isTouchScreenDevice } from "../../utils";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { coordinatesGeocoder } from "../../pages/publicMap/hooks/useMap/mapUtils";
import ParcelsControl from "./ParcelsControl";
import MeasurementsControl from "../../pages/publicMap/controls/MeasurementsControl";
import EagleViewControl from "./EagleViewControl";
import { useQuery } from "react-query";
import LocationsControl from "./LocationsControl";
import ReactDOM from "react-dom";
import Popup from "../../pages/publicMap/popup";
import { useSelector } from "react-redux";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "./styles.css";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Container = styled.div`
  height: 320px;
  width: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Instructions = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  position: absolute;
  text-align: center;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  padding: 5px 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  z-index: 1000;
  display: block;
`;

const Map = ({ filterValues, setFilterValues }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [map, setMap] = useState();
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [measurementsVisible, setMeasurementsVisible] = useState(false);
  const [parcelsVisible, setParcelsVisible] = useState(false);
  const [locationsVisible, setLocationsVisible] = useState(false);
  const [eagleViewVisible, setEagleViewVisible] = useState(false);
  const coordinatesContainerRef = useRef(null);
  const instructionsRef = useRef(null);
  const longRef = useRef(null);
  const latRef = useRef(null);
  const eleRef = useRef(null);
  const polygonRef = useRef(null);
  const radiusRef = useRef(null);
  const pointRef = useRef(null);
  const lineRef = useRef(null);
  const measurementsContainerRef = useRef(null);
  const mapContainerRef = useRef(null); // create a reference to the map container
  const theme = useSelector((state) => state.themeReducer);

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );

  const { data } = useQuery(
    ["ui-list-wells-table-dd"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-list-wells-table`,
          { headers }
        );

        //filters out any well that does not have geometry data
        const filterData = data.filter(
          (location) => location.location_geometry
        );
        return filterData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  async function getElevation() {
    const { data: elevation } = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/lidar-intersect/${latRef.current.innerHTML}/${longRef.current.innerHTML}`
    );

    eleRef.current.innerHTML = elevation;
  }
  //adds control features as extended by MapboxDrawGeodesic (draw circle)
  let modes = MapboxDraw.modes;
  modes = MapboxDrawGeodesic.enable(modes);

  const [draw] = useState(
    new MapboxDraw({
      modes,
      controls: {
        polygon: true,
        point: true,
        trash: true,
        line_string: true,
      },
      displayControlsDefault: false,
      userProperties: true,
    })
  );

  useEffect(() => {
    measurementsVisible
      ? (measurementsContainerRef.current.style.display = "block")
      : (measurementsContainerRef.current.style.display = "none");
  }, [measurementsVisible]);

  //create map and apply all controls
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center:
        filterValues.longitude_dd === "" || filterValues.latitude_dd === ""
          ? STARTING_LOCATION
          : [filterValues.longitude_dd, filterValues.latitude_dd],
      zoom:
        filterValues.longitude_dd === "" || filterValues.latitude_dd === ""
          ? 9
          : 16,
    });

    //event listener to run function updateArea during each draw action to handle measurements popup
    const drawActions = ["draw.create", "draw.update", "draw.delete"];
    drawActions.forEach((item) => {
      map.on(item, (event) => {
        const geojson = event.features[0];
        const type = event.type;
        updateArea(
          geojson,
          type,
          polygonRef,
          radiusRef,
          pointRef,
          lineRef,
          measurementsContainerRef,
          draw,
          setMeasurementsVisible
        );
      });
    });

    //top left controls
    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        localGeocoder: coordinatesGeocoder,
        zoom: 16,
        mapboxgl: mapboxgl,
        reverseGeocode: true,
        placeholder: "Address/Coords",
        limit: 3,
      }),
      "top-left"
    );
    map.addControl(new mapboxgl.FullscreenControl(), "top-left");

    //top right controls
    //loop through each base layer and add a layer toggle for that layer
    // [{ url: "outdoors-v11", icon: "park" }].forEach((layer) => {
    //   return map.addControl(
    //     new ToggleBasemapControl(layer.url, layer.icon),
    //     "top-right"
    //   );
    // });

    //bottom right controls
    //draw controls do not work correctly on touch screens
    !isTouchScreenDevice() &&
      map.addControl(draw, "bottom-right") &&
      !isTouchScreenDevice() &&
      map.addControl(new DragCircleControl(draw), "bottom-right");

    //bottom left controls
    map.addControl(
      new mapboxgl.ScaleControl({ unit: "imperial" }),
      "bottom-left"
    );
    map.addControl(
      new RulerControl({
        units: "feet",
        labelFormat: (n) => `${n.toFixed(2)} ft`,
      }),
      "bottom-left"
    );

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainerRef.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (mapIsLoaded && data?.length > 0 && typeof map != "undefined") {
      const marker = new mapboxgl.Marker({
        draggable: true,
        color: "red",
      })
        .setLngLat(
          filterValues.longitude_dd === "" || filterValues.latitude_dd === ""
            ? STARTING_LOCATION
            : [filterValues.longitude_dd, filterValues.latitude_dd]
        )
        .addTo(map);

      if (filterValues.longitude_dd && filterValues.latitude_dd) {
        const lngLat = marker.getLngLat();
        coordinatesContainerRef.current.style.display = "block";
        instructionsRef.current.innerHTML =
          "Drag and place marker to update coordinates and elevation fields";
        longRef.current.innerHTML = lngLat.lng;
        latRef.current.innerHTML = lngLat.lat;
        getElevation();
      }

      if (!map.getSource("eagleview-2024")) {
        map.addSource("eagleview-2024", {
          id: "eagleview-2024",
          type: "raster",
          tiles: [
            "https://svc.pictometry.com/Image/0E62A373-C685-DE24-B0F3-5457065B8906/tms/1.0.0/PICT-TXBELL24-TI1GPeIqGy/{z}/{x}/{y}.png",
          ],
          tileSize: 256,
        });
        map.addLayer(
          eagleView2024,
          !isTouchScreenDevice() ? "gl-draw-polygon-fill-inactive.cold" : null
        );
      }

      if (!map.getSource("bell-parcels")) {
        map.addSource("bell-parcels", {
          type: "vector",
          url: "mapbox://txclearwater.bell_cad_parcels",
        });

        map.addLayer(bellParcelsFill);
        map.addLayer(bellParcelsLine);
        map.addLayer(bellParcelsSymbol);
      }

      if (!map.getSource("locations")) {
        map.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: data.map((location) => {
              return {
                type: "Feature",
                id: location.well_ndx,
                properties: {
                  ...location,
                },
                geometry: {
                  type: location.location_geometry.type,
                  coordinates: location.location_geometry.coordinates,
                },
              };
            }),
          },
        });
        if (!map.getLayer("locations")) {
          map.addLayer({
            ...locationsLayer,
            layout: {
              visibility: "none",
            },
          });

          //add labels for locations points
          map.addLayer({
            ...locationsLabelsLayer,
            layout: {
              visibility: "none",
            },
          });
        }
      }

      const onDragEnd = (marker) => {
        const lngLat = marker.getLngLat();
        coordinatesContainerRef.current.style.display = "block";
        instructionsRef.current.innerHTML =
          "Click coordinate or elevation to copy individual result to clipboard";
        longRef.current.innerHTML = lngLat.lng.toFixed(7);
        setFilterValues("longitude_dd", lngLat.lng.toFixed(7));
        latRef.current.innerHTML = lngLat.lat.toFixed(7);
        setFilterValues("latitude_dd", lngLat.lat.toFixed(7));
        getElevation();
      };

      marker.on("dragend", () => onDragEnd(marker));

      // //handles copying coordinates and measurements to the clipboard
      const copyableRefs = [
        longRef,
        latRef,
        eleRef,
        polygonRef,
        radiusRef,
        pointRef,
        lineRef,
      ];
      copyableRefs.forEach((ref) => {
        ref.current.addEventListener("click", (e) =>
          handleCopyCoords(e.target.textContent)
        );
      });

      map.on("click", (e) => {
        map.fire("closeAllPopups");

        const features = map.queryRenderedFeatures(e.point);

        const coordinates = features[0]?.properties?.latitude_dd
          ? [
              features[0].properties.longitude_dd,
              features[0].properties.latitude_dd,
            ]
          : [e.lngLat.lng, e.lngLat.lat];

        const popupLayerIds = ["locations", "bell-parcels-fill"];

        const myFeatures = features.filter((feature) =>
          popupLayerIds.includes(feature?.layer?.id)
        );

        if (myFeatures.length > 0) {
          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    renderGraphButtons={false}
                    layers={[
                      locationsLayer,
                      bellParcelsFill,
                      bellParcelsLine,
                      bellParcelsSymbol,
                    ]}
                    features={myFeatures}
                    height="144px"
                    width="252px"
                    size="small"
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        }
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on("mouseenter", "locations", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "locations", () => {
        map.getCanvas().style.cursor = "";
      });
    }
  }, [mapIsLoaded, map, data]); //eslint-disable-line

  const bellParcelLayers = [
    "bell-parcels-fill",
    "bell-parcels-line",
    "bell-parcels-symbol",
  ];
  useEffect(() => {
    if (
      map !== undefined &&
      map.getLayer("bell-parcels-fill") &&
      map.getLayer("bell-parcels-line") &&
      map.getLayer("bell-parcels-symbol")
    ) {
      if (!parcelsVisible) {
        bellParcelLayers.forEach((layer) =>
          map.setLayoutProperty(layer, "visibility", "none")
        );
      } else {
        bellParcelLayers.forEach((layer) =>
          map.setLayoutProperty(layer, "visibility", "visible")
        );
      }
    }
  }, [parcelsVisible]); // eslint-disable-line

  const locationsLayers = ["locations", "locations-labels"];
  useEffect(() => {
    if (
      map !== undefined &&
      map.getLayer("locations") &&
      map.getLayer("locations-labels")
    ) {
      if (!locationsVisible) {
        locationsLayers.forEach((layer) =>
          map.setLayoutProperty(layer, "visibility", "none")
        );
      } else {
        locationsLayers.forEach((layer) =>
          map.setLayoutProperty(layer, "visibility", "visible")
        );
      }
    }
  }, [locationsVisible]); // eslint-disable-line

  useEffect(() => {
    if (map !== undefined && map.getLayer("eagleview-2024")) {
      if (!eagleViewVisible) {
        map.setLayoutProperty("eagleview-2024", "visibility", "none");
      } else {
        map.setLayoutProperty("eagleview-2024", "visibility", "visible");
      }
    }
  }, [eagleViewVisible]); // eslint-disable-line

  const handleClearMeasurements = () => {
    draw.deleteAll();
    polygonRef.current.innerHTML = "";
    radiusRef.current.innerHTML = "";
    pointRef.current.innerHTML = "";
    lineRef.current.innerHTML = "";
    setMeasurementsVisible(false);
  };

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="map"
          id="map"
          style={{ padding: "0" }}
        >
          <Typography variant="h4" ml={2}>
            Map (Coordinates & Elevation Selector)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0" }}>
          <Container>
            <MapContainer ref={mapContainerRef}>
              <EagleViewControl
                open={eagleViewVisible}
                onToggle={() => setEagleViewVisible(!eagleViewVisible)}
                top={59}
              />

              <LocationsControl
                open={locationsVisible}
                onToggle={() => setLocationsVisible(!locationsVisible)}
                top={98}
              />

              <ParcelsControl
                open={parcelsVisible}
                onToggle={() => setParcelsVisible(!parcelsVisible)}
                top={137}
              />

              <CoordinatesPopup
                coordinatesContainerRef={coordinatesContainerRef}
                longRef={longRef}
                latRef={latRef}
                eleRef={eleRef}
                title="Red marker:"
              />
              <MeasurementsPopup
                measurementsContainerRef={measurementsContainerRef}
                radiusRef={radiusRef}
                polygonRef={polygonRef}
                pointRef={pointRef}
                lineRef={lineRef}
                onHide={() => setMeasurementsVisible(false)}
                onClear={handleClearMeasurements}
              />
              <Instructions ref={instructionsRef}>
                Drag and place marker to generate coordinates and elevation
                fields
              </Instructions>
            </MapContainer>
            {!measurementsVisible && !isTouchScreenDevice() && (
              <MeasurementsControl
                open={measurementsVisible}
                onToggle={() => setMeasurementsVisible(!measurementsVisible)}
                right={49}
                bottom={30}
              />
            )}
          </Container>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Map;
