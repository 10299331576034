import MaterialTable, { MTableBodyRow } from "material-table";
import { TABLE_OPTIONS } from "./constants";
import {
  ClearAll,
  TableChart,
  Toc,
  ViewAgenda,
  ViewWeek,
} from "@material-ui/icons";
import { isWidthDown, withWidth } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import styled from "styled-components/macro";

const TableContainer = styled("div")`
  overflow: auto;
  min-height: 400px;
`;

const StyledTableRow = styled(MTableBodyRow)`
  background-color: ${({ $isSelected, $index }) =>
    $isSelected
      ? "#FFFF99 !important"
      : $index % 2 === 0
      ? "#fafafa !important"
      : "inherit"};
  border: 1px solid rgba(224, 224, 224, 1);
  &:hover {
    background-color: ${({ $isInverted }) =>
      $isInverted ? "inherit" : "#ffffd6 !important"};
  }
`;

const ResultsTable = ({
  isLoadingTable,
  version,
  tableRef,
  columns,
  tableData,
  invert,
  handleToggleInvert,
  handleToggleWidth,
  clearFilters,
  handleRecordSelection,
  handleFilterChange,
  selectedRecords,
  fullWidth,
  width,
}) => {
  const Row = memo(({ isSelected, isInverted, index, ...rest }) => (
    <StyledTableRow
      {...rest}
      $isSelected={isSelected}
      $index={index}
      $isInverted={isInverted}
    />
  ));

  const tableActions = useMemo(
    () => [
      {
        icon: () => (invert ? <Toc /> : <TableChart />),
        tooltip: "Toggle View-Only Cross-Tab for Selected Records",
        isFreeAction: true,
        onClick: handleToggleInvert,
        disabled: !selectedRecords.length && !invert,
      },
      {
        icon: () => (fullWidth ? <ViewWeek /> : <ViewAgenda />),
        tooltip: "Toggle Full-Width",
        isFreeAction: true,
        onClick: handleToggleWidth,
        disabled: isWidthDown("md", width),
      },
      {
        icon: () => <ClearAll />,
        tooltip: "Reset Data",
        isFreeAction: true,
        onClick: clearFilters,
      },
    ],
    [
      invert,
      selectedRecords.length,
      fullWidth,
      width,
      handleToggleInvert,
      handleToggleWidth,
      clearFilters,
    ]
  );

  return (
    <TableContainer>
      <MaterialTable
        key={version}
        tableRef={tableRef}
        title="Water Quality Records Search Results"
        columns={columns.map((col) => ({ ...col }))}
        data={tableData}
        isLoading={isLoadingTable}
        options={TABLE_OPTIONS}
        onRowClick={(event, rowData) =>
          !invert && handleRecordSelection(rowData.well_ndx)
        }
        onFilterChange={handleFilterChange}
        components={{
          Row: (props) => (
            <Row
              {...props}
              isSelected={
                !invert && selectedRecords.includes(props.data.well_ndx)
              }
              isInverted={invert}
            />
          ),
        }}
        actions={tableActions}
      />
    </TableContainer>
  );
};

export default memo(withWidth()(ResultsTable));
