import { useApp } from "../../../../AppProvider";
import { useSelector } from "react-redux";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import {
  bellParcelsFill,
  bellParcelsLine,
  bellParcelsSymbol,
  DUMMY_BASEMAP_LAYERS,
  eagleView2024,
  locationsLabelsLayer,
} from "../../../../utils/map";
import ResetZoomControl from "../../../../components/map/ResetZoomControl";
import debounce from "lodash.debounce";
import { EXCLUDED_POPUP_FIELDS, LOCATIONS_LAYER } from "./constants";
import ReactDOM from "react-dom";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import createTheme from "../../../../theme";
import { ThemeProvider } from "styled-components/macro";
import MainPopup from "../../../../components/map/components/MainPopup";
import { lineColors } from "../../../../utils";
import { create } from "jss";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const useResultsMap = (
  data,
  filteredWellNdx,
  selectedRecords,
  handleRecordSelection,
  handleSelectPDF
) => {
  const { currentUser } = useApp();
  const theme = useSelector((state) => state.themeReducer);

  const lastHoveredId = useRef(null);
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const popUpRef = useRef(
    new mapboxgl.Popup({ maxWidth: "400px", offset: 15, focusAfterOpen: false })
  );
  const [parcelsVisible, setParcelsVisible] = useState(false);
  const [eagleViewVisible, setEagleViewVisible] = useState(false);

  const fuzzySearchData = useMemo(
    () => data?.data?.features?.map((feature) => feature.properties) || [],
    [data]
  );

  const bounds = useMemo(() => {
    const allCoords = data?.data?.features?.map((item) => [
      item.properties.longitude_dd,
      item.properties.latitude_dd,
    ]);

    if (allCoords?.length) {
      return allCoords.reduce(
        (bounds, coord) => bounds.extend(coord),
        new mapboxgl.LngLatBounds(allCoords[0], allCoords[0])
      );
    }

    return null;
  }, [data]);

  const handleMouseMove = useCallback(
    (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["locations"],
      });
      if (features.length > 0) {
        const currentHoverId = features[0].properties.well_ndx;
        if (currentHoverId !== lastHoveredId.current) {
          map.setFeatureState(
            {
              source: "locations",
              id: currentHoverId,
            },
            {
              hover: true,
            }
          );
          if (lastHoveredId.current !== null) {
            map.setFeatureState(
              {
                source: "locations",
                id: lastHoveredId.current,
              },
              {
                hover: false,
              }
            );
          }
          lastHoveredId.current = currentHoverId;
          map.getCanvas().style.cursor = "pointer";
        }
      } else if (lastHoveredId.current !== null) {
        map.setFeatureState(
          {
            source: "locations",
            id: lastHoveredId.current,
          },
          {
            hover: false,
          }
        );
        lastHoveredId.current = null;
        map.getCanvas().style.cursor = "";
      }
    },
    [map]
  );

  const toggleParcelsVisibility = useCallback(() => {
    setParcelsVisible((prevState) => {
      const newState = !prevState;
      const visibility = newState ? "visible" : "none";
      ["bell-parcels-fill", "bell-parcels-line", "bell-parcels-symbol"].forEach(
        (layer) => map.setLayoutProperty(layer, "visibility", visibility)
      );
      return newState;
    });
  }, [map]);

  const toggleEagleViewVisibility = useCallback(() => {
    setEagleViewVisible((prevState) => {
      const newState = !prevState;
      const visibility = newState ? "visible" : "none";
      map.setLayoutProperty("eagleview-2024", "visibility", visibility);
      return newState;
    });
  }, [map]);

  const handleSearchSelect = useCallback(
    (result) => {
      map?.flyTo({
        center: result?.location_geometry?.coordinates,
        zoom: 16,
      });
    },
    [map]
  );

  const addMapSourcesAndLayers = useCallback(() => {
    if (!map || !data?.data) return;

    if (!map.getSource("eagleview-2024")) {
      map.addSource("eagleview-2024", {
        type: "raster",
        tiles: [
          "https://svc.pictometry.com/Image/0E62A373-C685-DE24-B0F3-5457065B8906/tms/1.0.0/PICT-TXBELL24-TI1GPeIqGy/{z}/{x}/{y}.png",
        ],
        tileSize: 256,
      });
      map.addLayer(eagleView2024);
    }

    if (!map.getSource("bell-parcels")) {
      map.addSource("bell-parcels", {
        type: "vector",
        url: "mapbox://txclearwater.bell_cad_parcels",
      });
      map.addLayer(bellParcelsFill);
      map.addLayer(bellParcelsLine);
      map.addLayer(bellParcelsSymbol);
    }

    if (!map.getSource("locations")) {
      map.addSource("locations", data);
      map.addLayer(LOCATIONS_LAYER);
      map.addLayer(locationsLabelsLayer);
    }

    map.on("click", "locations", (e) => {
      let feature = e.features[0];

      const coordinates = feature.geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      handleRecordSelection(feature.properties.well_ndx);

      const popupNode = document.createElement("div");
      ReactDOM.render(
        <StylesProvider jss={jss}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <MainPopup
                excludeFields={EXCLUDED_POPUP_FIELDS}
                feature={feature}
                currentUser={currentUser}
                handleSelectPDF={handleSelectPDF}
              />
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>,
        popupNode
      );

      popUpRef.current
        .setLngLat(coordinates)
        .setDOMContent(popupNode)
        .addTo(map);
    });

    map.on("click", (e) => {
      const features = map.queryRenderedFeatures(e.point);
      const coordinates = [e.lngLat.lng, e.lngLat.lat];
      const popupLayerIds = [
        "bell-parcels-fill",
        "bell-parcels-line",
        "bell-parcels-symbol",
      ];

      if (features.length > 0 && popupLayerIds.includes(features[0].layer.id)) {
        const feature = features[0];

        const popupNode = document.createElement("div");
        ReactDOM.render(
          <StylesProvider jss={jss}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <MainPopup
                  excludeFields={[]}
                  feature={feature}
                  currentUser={currentUser}
                />
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>,
          popupNode
        );
        popUpRef.current
          .setLngLat(coordinates)
          .setDOMContent(popupNode)
          .addTo(map);
      }
    });

    map.on("mousemove", handleMouseMove);

    map.on("mouseleave", () => {
      if (lastHoveredId.current !== null) {
        map.setFeatureState(
          {
            source: "locations",
            id: lastHoveredId.current,
          },
          {
            hover: false,
          }
        );
        lastHoveredId.current = null;
        map.getCanvas().style.cursor = "";
      }
    });
  }, [
    currentUser,
    data,
    handleMouseMove,
    handleRecordSelection,
    handleSelectPDF,
    map,
    theme.currentTheme,
  ]);

  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainerRef.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (bounds && mapContainerRef.current) {
      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      });

      newMap.addControl(new mapboxgl.NavigationControl(), "top-left");
      newMap.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        "top-left"
      );
      newMap.addControl(new mapboxgl.FullscreenControl());
      newMap.addControl(new ResetZoomControl(), "top-left");

      newMap.on("load", () => {
        newMap.fitBounds(bounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
        });
        setMap(newMap);
      });

      return () => {
        if (newMap) {
          newMap.remove();
          setMap(null);
        }
      };
    }
  }, [bounds]);

  useEffect(() => {
    if (map && data?.data) addMapSourcesAndLayers();

    return () => {
      if (map) {
        map.off("click");
        map.off("mousemove", handleMouseMove);
        map.off("mouseleave");
      }
    };
  }, [map, data?.data, handleMouseMove, addMapSourcesAndLayers]);

  useEffect(() => {
    if (map?.getLayer("locations")) {
      map.setPaintProperty("locations", "circle-color", [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        "#ffffd6",
        ["in", ["get", "well_ndx"], ["literal", selectedRecords]],
        "#FFFF99",
        ["in", ["get", "well_status"], "Plugged"],
        "#880000",
        ["in", ["get", "well_status"], "Never Drilled"],
        "yellow",
        ["boolean", ["get", "is_well_owner"], false],
        lineColors.orange,
        lineColors.lightBlue,
      ]);

      map.setPaintProperty("locations", "circle-stroke-width", [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        3,
        ["in", ["get", "well_ndx"], ["literal", selectedRecords]],
        2,
        1,
      ]);

      map.setPaintProperty("locations", "circle-stroke-color", [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        "black",
        ["in", ["get", "well_ndx"], ["literal", selectedRecords]],
        "black",
        "white",
      ]);
    }
  }, [map, selectedRecords]);

  useEffect(() => {
    if (map?.getSource("locations")) {
      map.setFilter("locations", [
        "in",
        ["get", "well_ndx"],
        ["literal", filteredWellNdx],
      ]);
    }
  }, [map, filteredWellNdx]);

  return {
    mapContainerRef,
    toggleParcelsVisibility,
    toggleEagleViewVisibility,
    handleSearchSelect,
    map,
    parcelsVisible,
    eagleViewVisible,
    fuzzySearchData,
  };
};

export default useResultsMap;
