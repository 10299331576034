import React, { memo } from "react";
import styled from "styled-components/macro";
import FuzzySearch from "../../../publicMap/filters/fuzzySearch";
import EagleViewControl from "../../../../components/map/EagleViewControl";
import ParcelsControl from "../../../../components/map/ParcelsControl";
import Loader from "../../../../components/Loader";
import useResultsMap from "./useResultsMap";

const MapContainer = styled("div")`
  height: 100%;
  min-height: 400px;
  width: 100%;
`;

const ResultsMap = ({
  data,
  filteredWellNdx,
  selectedRecords,
  handleRecordSelection,
  handleSelectPDF,
}) => {
  const {
    mapContainerRef,
    toggleParcelsVisibility,
    toggleEagleViewVisibility,
    handleSearchSelect,
    parcelsVisible,
    eagleViewVisible,
    fuzzySearchData,
  } = useResultsMap(
    data,
    filteredWellNdx,
    selectedRecords,
    handleRecordSelection,
    handleSelectPDF
  );

  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <MapContainer ref={mapContainerRef}>
          <FuzzySearch
            onSelect={handleSearchSelect}
            options={fuzzySearchData}
          />
          <EagleViewControl
            open={eagleViewVisible}
            onToggle={toggleEagleViewVisibility}
            top={59}
          />
          <ParcelsControl
            open={parcelsVisible}
            onToggle={toggleParcelsVisibility}
            top={98}
          />
        </MapContainer>
      )}
    </>
  );
};

export default memo(ResultsMap);
