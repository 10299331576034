import {
  FIELD_ORDER_FOR_CSV,
  GEOLOGIC_FORMATIONS_CONFIG,
  OMIT_FIELDS_FOR_CSV,
  SCREENING_INTERVALS_CONFIG,
} from "./constants";
import { titleize } from "inflected";
import moment from "moment";

/**
 * Converts a hex color code to an RGBA color string with the specified opacity.
 * @param {string} hex - The hex color code.
 * @param {number} opacity - The opacity value (0 to 1).
 * @returns {string} The RGBA color string.
 */
export function hexToRGBA(hex, opacity) {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

/**
 * Converts a hex color code to an object containing the red, green, and blue components.
 * @param {string} hex - The hex color code.
 * @returns {object} An object with r, g, and b properties.
 */
const hexToRgb = (hex) => {
  const cleanedHex = hex.replace("#", "");
  const bigint = parseInt(cleanedHex, 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
};

/**
 * Extracts the RGB components from a hex, RGB, or RGBA color string.
 * @param {string} color - The color string.
 * @returns {object} An object with r, g, and b properties.
 */
const extractRgb = (color) => {
  if (color.startsWith("#")) {
    return hexToRgb(color);
  } else if (color.startsWith("rgb")) {
    const rgba = color.match(/\d+/g).map(Number);
    return { r: rgba[0], g: rgba[1], b: rgba[2] };
  }
  return { r: 0, g: 0, b: 0 }; // Default to black if color format is unrecognized
};

/**
 * Determines the best contrast color (black or white) for a given background color.
 * @param {string} bgColor - The background color.
 * @returns {string} The contrast color ("black" or "white").
 */
export const getContrastColor = (bgColor) => {
  const { r, g, b } = extractRgb(bgColor);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "black" : "white";
};

export const adjustOpacity = (rgba, opacity) => {
  const parts = rgba.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/
  );
  return `rgba(${parts[1]}, ${parts[2]}, ${parts[3]}, ${opacity})`;
};

export const setColorOpacity = (color, opacity) =>
  color.replace(/[\d.]+\)$/g, `${opacity})`);

const formatCSVField = (field) => {
  if (field === null || field === undefined) return "";
  const strField = field.toString();
  return strField.replace(/\n/g, "; ").replace(/"/g, '""');
};

export const formatCSVData = (
  { wells, startDate, endDate },
  wellsOptions,
  wellsFormations,
  screeningIntervals,
  rawGraphData
) => {
  if (
    !wells ||
    !wellsOptions ||
    !wellsFormations ||
    !screeningIntervals ||
    !rawGraphData
  ) {
    return [];
  }

  const csvData = [];

  // Helper function to get well label
  const getWellLabel = (well_ndx) => {
    const well = wellsOptions.find((w) => w.value === well_ndx);
    return well ? well.label : `Well ${well_ndx}`;
  };

  wells.forEach((well_ndx) => {
    const wellLabel = getWellLabel(well_ndx);
    const wellSectionHeader = [`Well: ${wellLabel}`];
    const dateRangeHeader = [`Date Range: ${startDate} to ${endDate}`];

    csvData.push(wellSectionHeader);
    csvData.push(dateRangeHeader);
    csvData.push([]);

    // Add well formations data
    const wellFormations = wellsFormations.find(
      (wf) => wf.well_ndx === well_ndx
    );
    if (wellFormations) {
      csvData.push(["Formations"]);
      Object.keys(GEOLOGIC_FORMATIONS_CONFIG).forEach((key) => {
        csvData.push([
          GEOLOGIC_FORMATIONS_CONFIG[key].label,
          wellFormations[key] || "N/A",
        ]);
      });
      csvData.push([]);
    }

    // Add screening intervals data
    const wellScreeningIntervals = screeningIntervals.find(
      (si) => si.well_ndx === well_ndx
    );
    if (wellScreeningIntervals) {
      csvData.push(["Screening Intervals"]);
      Object.keys(SCREENING_INTERVALS_CONFIG).forEach((key) => {
        csvData.push([
          SCREENING_INTERVALS_CONFIG[key].label,
          wellScreeningIntervals[key] || "N/A",
        ]);
      });
      csvData.push([]);
    }

    // Add raw graph data
    const wellData = rawGraphData
      .filter((data) => data.well_ndx === well_ndx)
      .map((data) => {
        const orderedData = {};
        FIELD_ORDER_FOR_CSV.forEach((field) => {
          if (
            data[field] !== undefined &&
            !OMIT_FIELDS_FOR_CSV.includes(field)
          ) {
            orderedData[titleize(field)] =
              field === "collected_date"
                ? moment(data[field]).format("MM/DD/YYYY")
                : data[field];
          }
        });
        return orderedData;
      });

    if (wellData.length) {
      csvData.push(["Data"]);
      const headers = FIELD_ORDER_FOR_CSV.filter(
        (field) => !OMIT_FIELDS_FOR_CSV.includes(field)
      ).map((field) => titleize(field));
      csvData.push(headers);

      wellData.forEach((data) => {
        const row = headers.map((header) => formatCSVField(data[header]) || "");
        csvData.push(row);
      });
      csvData.push([], []);
    }
  });

  return csvData;
};
