import React from "react";
import { Typography, CardContent } from "@material-ui/core";
import styled from "styled-components";

const StyledCardContent = styled(CardContent)`
  flex-grow: 1;
`;

const wellDetailsConfig = [
  { label: "Aquifer:", key: "aquifer_name" },
  { label: "Texas Grid ID:", key: "texas_grid_id" },
  { label: "Well Elevation, Ft:", key: "elevation_ftabmsl" },
  { label: "Well Depth, Ft:", key: "well_depth_ft" },
  { label: "Owner:", key: "well_owner" },
  { label: "Date Drilled:", key: "date_drilled" },
  { label: "Notes:", key: "notes" },
  {
    label: "Location:",
    key: "location",
    format: (data) => `${data.latitude_dd}, ${data.longitude_dd}`,
  },
];

const renderWellDetail = (wellData, config) => (
  <Typography key={config.key} variant={config.variant || "body2"}>
    <strong>{config.label} </strong>
    {config.format ? config.format(wellData) : wellData[config.key]}
  </Typography>
);

const WellDetails = ({ metaData, well }) => {
  const wellData = metaData?.[well];
  if (!wellData) return null;

  return (
    <>
      <Typography variant="h5">
        <strong>Well: </strong>
        {`${wellData.well_name} ${wellData.cuwcd_well_number}`}
      </Typography>
      <Typography variant="subtitle1">
        <strong>State Well No.: </strong>
        {wellData.state_well_number}
      </Typography>
      <StyledCardContent>
        {wellDetailsConfig.map((config) => renderWellDetail(wellData, config))}
      </StyledCardContent>
    </>
  );
};

export default WellDetails;
