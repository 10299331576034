import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  BASE_URL,
  DEFAULT_END_DATE,
  DEFAULT_QUERY_OPTIONS,
  DEFAULT_START_DATE,
  GEOLOGIC_FORMATIONS_CONFIG,
  SCREENING_INTERVALS_CONFIG,
} from "../constants";
import { schemeCategory10 } from "d3-scale-chromatic";
import { useAxiosWithAuth } from "./useAxiosWithAuth";
import { formatCSVData, getContrastColor, hexToRGBA } from "../utils";

const transformGraphData = (data) => {
  let colorCount = 0;
  const wellsData = data.reduce((acc, item) => {
    const color = schemeCategory10[colorCount % 10];
    const colorRGBA = hexToRGBA(color, 0.5);
    if (!acc[item.well_ndx]) {
      acc[item.well_ndx] = {
        label: `${item.well_name} ${item.cuwcd_well_number}`,
        data: [],
        originalBorderColor: color,
        borderColor: color,
        originalBackgroundColor: colorRGBA,
        backgroundColor: colorRGBA,
        borderWidth: 2,
        pointHoverRadius: 9,
        pointRadius: 7,
        fill: "start",
      };
      colorCount++;
    }
    acc[item.well_ndx].data.push({
      x: item.collected_date,
      y: item.avg_dtw_ft,
    });
    return acc;
  }, {});

  return { datasets: Object.values(wellsData) };
};

const transformAnnotations = (
  formationsData,
  screeningData,
  annotationsVisibility
) => {
  const annotations = {};

  const createAnnotation = (
    wellId,
    depth,
    key,
    config,
    annotationsVisibilityId,
    index
  ) => {
    if (depth !== null && depth !== undefined) {
      const annotationKey = `${key}_${wellId}`;
      annotations[annotationKey] = {
        type: "line",
        yScaleID: "yL",
        yMin: depth,
        yMax: depth,
        borderColor: config.color,
        borderWidth: 3,
        borderDash: config.borderDash || [],
        display: annotationsVisibility[`${annotationsVisibilityId}_${wellId}`],
        label: {
          position: config.position,
          yAdjust: config.yAdjust || 0,
          enabled: true,
          backgroundColor: config.color,
          borderColor: schemeCategory10[index % 10],
          borderRadius: 10,
          borderWidth: 4,
          color: getContrastColor(config.color),
          content: `${config.label}: ${depth} ft`,
          rotation: "auto",
          draggable: true,
        },
      };
    }
  };

  formationsData.forEach((formation, index) => {
    Object.entries(GEOLOGIC_FORMATIONS_CONFIG).forEach(([key, config]) => {
      createAnnotation(
        formation.well_ndx,
        formation[key],
        key,
        config,
        "formations",
        index
      );
    });
  });

  screeningData.forEach((screen, index) => {
    Object.entries(SCREENING_INTERVALS_CONFIG).forEach(([key, config]) => {
      createAnnotation(
        screen.well_ndx,
        screen[key],
        key,
        config,
        "screenings",
        index
      );
    });
  });

  return annotations;
};

export const useSignalFireTelemetry = () => {
  const { id } = useParams();
  const fetchWithAuth = useAxiosWithAuth(BASE_URL);

  const [inputState, setInputState] = useState({
    wells: id ? [+id] : [],
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  });
  const [annotationsVisibility, setAnnotationsVisibility] = useState({});

  const handleInputState = useCallback((key, value) => {
    setInputState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const toggleAnnotationVisibility = useCallback(
    (annotationsVisibilityId, key) => {
      setAnnotationsVisibility((prev) => ({
        ...prev,
        [`${annotationsVisibilityId}_${key}`]:
          !prev[`${annotationsVisibilityId}_${key}`],
      }));
    },
    []
  );

  const fetchDataCallback = useCallback(
    (endpoint, params) => fetchWithAuth(endpoint, params),
    [fetchWithAuth]
  );

  const { data: wellsList, isFetching: wellsOptionsIsLoading } = useQuery(
    "wellsList",
    () => fetchDataCallback("list-wells", {}),
    {
      staleTime: Infinity,
      onSuccess: (data) =>
        !id &&
        setInputState((prevState) => {
          return {
            ...prevState,
            wells: data.map((well) => well.well_ndx),
          };
        }),
    }
  );

  const { data: rawGraphData, isFetching: rawGraphDataIsLoading } = useQuery(
    ["graphData", inputState],
    () =>
      fetchDataCallback("graph-data", {
        startDate: inputState.startDate,
        endDate: inputState.endDate,
        wells: inputState.wells,
      }),
    {
      enabled: !!inputState.wells.length,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );

  const { data: wellsFormations, isFetching: wellsFormationsIsLoading } =
    useQuery(
      ["wellsFormations", inputState.wells],
      () =>
        fetchDataCallback("wells-formations", {
          wells: inputState.wells,
        }),
      { enabled: !!inputState.wells.length, ...DEFAULT_QUERY_OPTIONS }
    );

  const { data: screeningIntervals, isFetching: screeningIntervalsIsLoading } =
    useQuery(
      ["screeningIntervals", inputState.wells],
      () =>
        fetchDataCallback("screening-intervals", {
          wells: inputState.wells,
        }),
      { enabled: !!inputState.wells.length, ...DEFAULT_QUERY_OPTIONS }
    );

  const wellsOptions = useMemo(
    () =>
      wellsList?.map((well) => ({
        value: well.well_ndx,
        label: well.description,
      })) || [],
    [wellsList]
  );

  const graphData = useMemo(
    () => transformGraphData(rawGraphData || []),
    [rawGraphData]
  );

  const metaData = useMemo(() => {
    if (!rawGraphData) return {};
    return rawGraphData.reduce((acc, item) => {
      if (!acc[item.well_ndx]) {
        acc[item.well_ndx] = item;
      }
      return acc;
    }, {});
  }, [rawGraphData]);

  const annotationsGraphData = useMemo(
    () =>
      transformAnnotations(
        wellsFormations || [],
        screeningIntervals || [],
        annotationsVisibility
      ),
    [wellsFormations, screeningIntervals, annotationsVisibility]
  );

  useEffect(() => {
    const setAnnotationsVisibilityFromData = () => {
      const newannotationsVisibility = {};
      wellsFormations?.forEach((formation) => {
        newannotationsVisibility[`formations_${formation.well_ndx}`] = false;
      });
      screeningIntervals?.forEach((interval) => {
        newannotationsVisibility[`screenings_${interval.well_ndx}`] = false;
      });
      setAnnotationsVisibility(newannotationsVisibility);
    };

    setAnnotationsVisibilityFromData();
  }, [wellsFormations, screeningIntervals]);

  const getCSVData = useCallback(
    (well_ndx) => {
      const wells = well_ndx ? [well_ndx] : inputState.wells;
      return formatCSVData(
        { ...inputState, wells },
        wellsOptions,
        wellsFormations,
        screeningIntervals,
        rawGraphData
      );
    },
    [
      inputState,
      wellsOptions,
      wellsFormations,
      screeningIntervals,
      rawGraphData,
    ]
  );

  return {
    inputState,
    setInputState,
    handleInputState,
    wellsOptions,
    wellsOptionsIsLoading,
    graphData,
    metaData,
    annotationsGraphData,
    annotationsVisibility,
    toggleAnnotationVisibility,
    isLoading:
      rawGraphDataIsLoading ||
      wellsFormationsIsLoading ||
      screeningIntervalsIsLoading,
    getCSVData,
  };
};
