import { lineColors } from "../../../../utils";

export const DATE_TIME_FORMAT = "MM/DD/YYYY, h:mm A";
export const DATE_FORMAT = "MM/DD/YYYY";

export const PAGINATION_OPTIONS = [5, 10, 25, 50];
export const DEFAULT_PAGINATION = PAGINATION_OPTIONS[1];

export const USE_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
};

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api/water-quality-records-search`;

const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;

export const DEFAULT_INPUT_STATE = {
  startYear: lastYear,
  endYear: currentYear,
  isExempt: null,
  isActive: true,
  wellUses: [],
  managementZones: [],
  aquifers: [],
  wellStatuses: [8], //active
  parameters: [],
};

export const LOCATIONS_LAYER = {
  id: "locations",
  type: "circle",
  source: "locations",
  paint: {
    "circle-radius": 8,
    "circle-color": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "#ffffd6",
      ["in", ["get", "well_status"], "Plugged"],
      "#880000",
      ["in", ["get", "well_status"], "Never Drilled"],
      "yellow",
      ["boolean", ["get", "is_well_owner"], false],
      lineColors.orange,
      lineColors.lightBlue,
    ],
    "circle-stroke-width": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      3,
      1,
    ],
    "circle-stroke-color": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "black",
      "white",
    ],
  },
};

export const EXCLUDED_POPUP_FIELDS = [
  "id",
  "has_production",
  "has_waterlevels",
  "has_wqdata",
  "has_telemetry",
  "well_ndx",
  "location_geometry",
  "authorized_users",
  "is_well_owner",
  "tableData",
  "is_permitted",
  "is_exempt",
  "is_monitoring",
  "well_type",
  "tableData",
];

export const DEFAULT_HEADER_STYLE = {
  fontWeight: "600",
  textAlign: "center",
  padding: "3px",
  whiteSpace: "nowrap",
};

export const DEFAULT_CELL_STYLE = {
  borderLeft: "1px solid rgba(0,0,0,0.12)",
  borderRight: "1px solid rgba(0,0,0,0.12)",
  borderTop: "1px solid rgba(0,0,0,0.12)",
  padding: "3px",
  whiteSpace: "nowrap",
};

export const TABLE_OPTIONS = {
  emptyRowsWhenPaging: false,
  exportButton: { csv: true, pdf: false },
  exportAllData: true,
  padding: "dense",
  draggable: false,
  showTitle: false,
  filtering: true,
  pageSize: DEFAULT_PAGINATION,
  search: false,
  pageSizeOptions: PAGINATION_OPTIONS,
  headerStyle: DEFAULT_HEADER_STYLE,
  cellStyle: DEFAULT_CELL_STYLE,
};

export const PARAMETERS_OPTIONS = [
  { value: "ecoli", label: "E. Coli", type: "multiselect" },
  { value: "coliform", label: "Coliform", type: "multiselect" },
  { value: "conductivity", label: "Conductivity, uS/cm", type: "range" },
  { value: "tds", label: "TDS, mg/L", type: "range" },
  { value: "salinity", label: "Salinity, mg/L", type: "range" },
  { value: "ph", label: "pH", type: "range" },
  { value: "sodium_ion", label: "Sodium Ion, mg/L", type: "range" },
  { value: "alkalinity", label: "Alkalinity, mg/L CaCO3", type: "range" },
  { value: "hardness", label: "Hardness, mg/L CaCO3", type: "range" },
  { value: "nitrite", label: "Nitrite, mg/L N", type: "range" },
  { value: "nitrate", label: "Nitrate, mg/L N", type: "range" },
  { value: "phosphate", label: "Phosphate, mg/L", type: "range" },
  { value: "sulfate", label: "Sulfate, mg/L", type: "range" },
  { value: "fluoride", label: "Fluoride, mg/L", type: "range" },
  { value: "chloride", label: "Chloride, mg/L", type: "range" },
  { value: "calcium", label: "Calcium, mg/L", type: "range" },
  { value: "magnesium", label: "Magnesium, mg/L", type: "range" },
  { value: "caco3", label: "CaCO3, mg/L CaCO3", type: "range" },
];
