export const chartBackgroundPlugin = {
  id: "chartFillBackground",
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.fillStyle = "#eeeeee";
    ctx.fillRect(0, 0, chart.width, chart.height);
  },
};

export const annotatedVerticalLinePlugin = {
  id: "annotatedVerticalLine",
  afterDraw: (chart) => {
    if (chart.tooltip?._active?.length) {
      let x = chart.tooltip._active[0].element.x;
      let yAxis = chart.scales.yL;
      let ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 9;
      ctx.strokeStyle = "rgba(0, 0, 255, 0.2)";
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgba(0, 0, 255, 0.8)";
      ctx.stroke();
      ctx.restore();
    }
  },
};
