import React, { useCallback } from "react";
import {
  Box,
  Button,
  Grid as MuiGrid,
  TextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { titleize } from "inflected";
import { indefiniteArticle } from "../../../utils";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const BorderBox = styled(Box)`
  width: 100%;
  padding: 16px 16px ${({ $isCreatingNew }) => ($isCreatingNew ? "16px" : 0)}
    16px;
  ${({ $isCreatingNew, $isError }) =>
    $isCreatingNew
      ? `border: 1px solid ${$isError ? "red" : "lightGrey"};`
      : "border: none;"}
  border-radius: 4px;
`;

const FIELDS_CONFIG = [
  {
    suffix: "lastname",
    label: "Last Name",
    required: true,
    md: 3,
  },
  {
    suffix: "firstname",
    label: "First Name",
    required: true,
    md: 3,
  },
  {
    suffix: "organization",
    label: "Organization",
    required: true,
  },
  { suffix: "address", label: "Address", md: 5 },
  { suffix: "city", label: "City", md: 3 },
  { suffix: "state", label: "State", md: 2 },
  { suffix: "zipcode", label: "Zip Code", md: 2 },
  { suffix: "email_1", label: "Email", type: "email" },
  { suffix: "phone_1", label: "Phone #", type: "tel" },
  { suffix: "email_2", label: "Secondary Email", type: "email" },
  { suffix: "phone_2", label: "Secondary Phone #", type: "tel" },
  {
    suffix: "notes",
    label: "Notes",
    multiline: true,
    rows: 2,
    md: 12,
  },
];

const generateFieldName = (role, suffix) => `rolo_${role}_${suffix}`;

function GenericSubForm({
  values,
  setFieldValue,
  role,
  data,
  setValues,
  creatingNew,
  setCreatingNew,
}) {
  const handleResetSubForm = useCallback(() => {
    const resetFields = FIELDS_CONFIG.reduce((acc, { suffix }) => {
      acc[`rolo_${role}_${suffix}`] = null;
      return acc;
    }, {});

    setValues((prevValues) => ({
      ...prevValues,
      [`${role}_rolo_ndx`]: data[`${role}_rolo_ndx`],
      [`rolo_${role}_add_new`]: !creatingNew,
      ...resetFields,
    }));

    setCreatingNew(!creatingNew);
  }, [creatingNew, role, data, setValues, setCreatingNew]);

  const isReferenceRequired = !(
    values[generateFieldName(role, "lastname")] ||
    values[generateFieldName(role, "firstname")] ||
    values[generateFieldName(role, "organization")]
  );

  const helperPrefix = React.useMemo(() => {
    const titledRole = titleize(role);
    const article = indefiniteArticle(titledRole);
    return `${titleize(article)} ${titledRole} reference is required.`;
  }, [role]);

  return (
    <BorderBox $isCreatingNew={creatingNew} $isError={isReferenceRequired}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" ml={4}>
          {`${
            creatingNew ? "Creating a New" : "Select a Preexisting"
          } Well ${titleize(role)}:`}
        </Typography>

        <Button
          style={{ width: "175px" }}
          variant="contained"
          color={creatingNew ? "default" : "primary"}
          onClick={handleResetSubForm}
        >
          {creatingNew ? "Cancel" : `Create New ${titleize(role)}`}
        </Button>
      </Box>

      {creatingNew && (
        <Grid mt={4} container spacing={2}>
          {FIELDS_CONFIG.map(
            ({
              suffix,
              label,
              required,
              type = "text",
              md = 6,
              multiline = false,
              rows = 1,
            }) => {
              const fieldName = generateFieldName(role, suffix);
              return (
                <Grid item xs={12} md={md} key={suffix}>
                  <TextField
                    variant="outlined"
                    label={label}
                    required={required}
                    error={required && isReferenceRequired}
                    helperText={
                      required && isReferenceRequired ? helperPrefix : ""
                    }
                    type={type}
                    multiline={multiline}
                    rows={rows}
                    onChange={(e) => setFieldValue(fieldName, e.target.value)}
                    value={values[fieldName] || ""}
                    fullWidth
                  />
                </Grid>
              );
            }
          )}
        </Grid>
      )}
    </BorderBox>
  );
}

export default GenericSubForm;
