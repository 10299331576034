import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import styled from "styled-components/macro";

const CheckboxContainer = styled(FormControl)`
  margin: 10px 0;
`;

export const SimpleCheckbox = ({ name, label, checked, error, onToggle }) => {
  return (
    <CheckboxContainer error={!!error} component="fieldset">
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onToggle}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </CheckboxContainer>
  );
};

export const TriStateCheckbox = ({ name, label, value, onChange }) => {
  const handleChange = () => {
    const newValue = value === null ? true : value === true ? false : null;
    onChange(name, newValue);
  };

  const isChecked = value === true;
  const isIndeterminate = value === null;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={handleChange}
          color="primary"
        />
      }
      label={label}
    />
  );
};
