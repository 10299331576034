import React from "react";
import { Typography } from "@material-ui/core";

const OptionsHeader = ({
  text,
  color = "secondary",
  variant = "subtitle1",
  ...rest
}) => (
  <Typography color={color} variant={variant} {...rest}>
    {text}
  </Typography>
);

export default OptionsHeader;
