import React, { memo } from "react";
import {
  Divider as MuiDivider,
  Grid,
  Typography,
  Paper as MuiPaper,
  Breadcrumbs as MuiBreadcrumbs,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import ResultsMap from "./ResultsMap";
import useResultsPage from "./useResultsPage";
import Report from "./Report";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import ResultsTable from "./ResultsTable";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

const ResultsPage = () => {
  const {
    mapData,
    selectedRecords,
    handleRecordSelection,
    handleSelectPDF,
    isLoadingTable,
    columns,
    selectedPDF,
    handleToggleWidth,
    fullWidth,
    tableData,
    handleToggleInvert,
    clearFilters,
    invert,
    filteredWellNdx,
    version,
    tableRef,
    handleFilterChange,
  } = useResultsPage();

  return (
    <>
      <Helmet title="Water Quality Records Search Results" />
      <Typography variant="h3" gutterBottom display="inline">
        Water Quality Records Search Results
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/data-access/tools/water-quality-records-search"
        >
          Water Quality Records Search
        </Link>
        <Typography>Results</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper p={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={fullWidth ? 12 : 6}>
            <ResultsMap
              data={mapData}
              filteredWellNdx={filteredWellNdx}
              selectedRecords={selectedRecords}
              handleRecordSelection={handleRecordSelection}
              handleSelectPDF={handleSelectPDF}
            />
          </Grid>
          <Grid item xs={12} lg={fullWidth ? 12 : 6}>
            <ResultsTable
              isLoadingTable={isLoadingTable}
              version={version}
              tableRef={tableRef}
              columns={columns}
              tableData={tableData}
              invert={invert}
              handleToggleInvert={handleToggleInvert}
              handleToggleWidth={handleToggleWidth}
              clearFilters={clearFilters}
              handleRecordSelection={handleRecordSelection}
              handleFilterChange={handleFilterChange}
              selectedRecords={selectedRecords}
              fullWidth={fullWidth}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper mt="20px">
        {selectedPDF && <Report selectedPDF={selectedPDF} />}
      </Paper>
    </>
  );
};

export default memo(ResultsPage);
