import React, { useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "@lrewater/lre-react";
import { Chip as MuiChip, FormControlLabel } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
  materialTable: {
    "& th:last-child": {
      textAlign: "left!important",
    },
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
  },
}));

const Chip = styled(MuiChip)(spacing);

const PermitsListForLoginsManagementTable = ({
  data,
  selections,
  onCheck,
  isLoading,
}) => {
  const classes = useStyles();

  const [exclude, setExclude] = useState(true);

  const handleExclude = () => {
    setExclude((state) => !state);
  };

  const filterData = (data) => {
    if (exclude) {
      return data.filter((d) => selections.includes(d.permit_ndx));
    }
    return data;
  };

  const columns = [
    {
      title: "Permit Number",
      field: "permit_number",
    },
    {
      title: "Permit Holder Name",
      field: "permit_holder_name",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    {
      title: "Associated Wells",
      field: "assoc_wells",
      render: (rowData) => {
        return rowData.assoc_wells.map((item) => (
          <Chip label={item} size="small" key={item} mb={1} mr={1} />
        ));
      },
    },
    {
      title: "Aggregated System Name",
      field: "agg_system_name",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    { title: "Permitted Value", field: "permitted_value", filtering: false },
    {
      title: "Primary Use",
      field: "use_desc",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
  ];

  const Toolbar = (props) => {
    const classes = useStyles();
    const { handleChange, checked } = props;
    return (
      <div>
        <MTableToolbar {...props} />
        <div className={classes.toolbar}>
          <FormControlLabel
            control={
              <Switch
                label=""
                checked={checked}
                value="exclude"
                name="exclude"
                onChange={handleChange}
              />
            }
            label={
              checked ? (
                <>
                  Toggle to view <em>all</em> permits
                </>
              ) : (
                <>
                  Toggle to view only <em>selected</em> permits
                </>
              )
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.materialTable}>
      <MaterialTable
        title=""
        columns={columns}
        data={filterData(data)}
        isLoading={isLoading}
        components={{
          Toolbar: (props) => {
            return (
              <Toolbar
                handleChange={handleExclude}
                checked={exclude}
                {...props}
              />
            );
          },
        }}
        options={{
          actionsCellStyle: { justifyContent: "center" },
          pageSize: 15,
          pageSizeOptions: [15, 30, 60],
          maxBodyHeight: 600,
          padding: "dense",
          selection: true,
          selectionProps: (rowData) => ({
            checked: selections.includes(rowData.permit_ndx),
          }),
          showTextRowsSelected: false,
          showSelectAllCheckbox: false,
          filtering: true,
        }}
        onSelectionChange={onCheck}
      />
    </div>
  );
};

export default PermitsListForLoginsManagementTable;
