import React from "react";
import styled from "styled-components/macro";

import parse from "html-react-parser";

import { formatBooleanTrueFalse } from "../../../utils";
import { isNullOrUndef } from "chart.js/helpers";
import { titleize } from "inflected";
import { locationsRowTitles } from "../../../utils/map";
//TODO possibly add a button for accessing PDF through map
// import { PictureAsPdf } from "@material-ui/icons";
// import { IconButton } from "@material-ui/core";

const PopupWrap = styled("div")`
  max-height: 200px;
  overflow-y: scroll;
`;

const PopupTable = styled("table")`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
`;

const PopupRow = styled("tr")`
  border-radius: 5px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled("td")`
  padding: 3px 6px;
  margin: 0;
`;

const PopupUl = styled("ul")`
  list-style-type: none;
  margin: 0 !important;
  padding: 3px 0;
`;

const MainPopup = ({
  excludeFields,
  feature,
  currentUser,
  // handleSelectPDF = null,
}) => {
  const properties = feature?.properties || feature;
  return (
    <>
      <PopupWrap>
        <h3>Properties</h3>
        <PopupTable>
          <tbody>
            {/*{handleSelectPDF && (*/}
            {/*  <PopupRow>*/}
            {/*    <PopupCell>*/}
            {/*      <strong>View PDF</strong>*/}
            {/*    </PopupCell>*/}
            {/*    <PopupCell>*/}
            {/*      <IconButton*/}
            {/*        size="small"*/}
            {/*        onClick={(e) => handleSelectPDF(e, properties?.well_ndx)}*/}
            {/*        disabled={!properties.cuwcd_well_number}*/}
            {/*        aria-label="View PDF"*/}
            {/*      >*/}
            {/*        <PictureAsPdf />*/}
            {/*      </IconButton>*/}
            {/*    </PopupCell>*/}
            {/*  </PopupRow>*/}
            {/*)}*/}
            {!currentUser?.isUser && properties.cuwcd_well_number && (
              <PopupRow>
                <PopupCell>
                  <strong>Edit Well</strong>
                </PopupCell>
                <PopupCell>
                  <a
                    href={`/models/dm-wells/${properties.id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Link
                  </a>
                </PopupCell>
              </PopupRow>
            )}
            {Object.entries(properties).map(([k, v]) => {
              //excludes 'excludedFields' and all values of "", null, or undefined
              if (excludeFields.includes(k) || v === "" || isNullOrUndef(v))
                return null;
              return (
                <PopupRow key={k}>
                  <PopupCell>
                    <strong>
                      {feature.source === "locations"
                        ? locationsRowTitles[k]
                        : titleize(k)}
                    </strong>
                  </PopupCell>
                  <PopupCell>
                    {typeof v === "string" && v.startsWith("<a ") ? (
                      <PopupUl>
                        {v.split(",").map((item) => (
                          <li key={item}>{parse(item)}</li>
                        ))}
                      </PopupUl>
                    ) : feature.source === "bell-parcels" &&
                      k === "prop_id_text" ? (
                      <a
                        target="_blank"
                        href={`https://esearch.bellcad.org/Property/View/${v}`}
                        rel="noreferrer"
                      >
                        <>{v}</>
                      </a>
                    ) : (
                      formatBooleanTrueFalse(v)
                    )}
                  </PopupCell>
                </PopupRow>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
    </>
  );
};

export default MainPopup;
