import React from "react";
import styled from "styled-components/macro";

import { Container, Grid } from "@material-ui/core";

import { BackgroundVideo } from "../../../components/BackgroundVideo";
import Link from "@material-ui/core/Link";

const Wrapper = styled.div`
  padding-top: 5rem;
  position: relative;
  text-align: center;
  overflow: hidden;

  color: ${(props) =>
    props.theme.palette.type === "dark"
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.primary};
`;

const Content = styled.div`
  position: relative;
  padding-bottom: ${(props) => props.theme.spacing(6)}px;
  line-height: 150%;
`;

const BrandLogo = styled.img`
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
  //margin-bottom: 20px;
`;

function Introduction() {
  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justify="center" spacing={4}>
          <Grid item xs={7} sm={6} md={5}>
            <BackgroundVideo mp4={"/static/video/water.mp4"} />
            <Content>
              <Link
                href="https://cuwcd.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <BrandLogo
                  src="/static/img/clearwater-logo-full.png"
                  alt="Clearwater Logo"
                />
              </Link>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Introduction;
