import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  Grid as MuiGrid,
  MenuItem,
  TextField as MuiTextfield,
  Typography as MuiTypography,
  InputAdornment,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Hidden,
  Card as MuiCard,
  CardContent,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TableContainer,
  TableHead,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableBody,
  Paper,
  Chip as MuiChip,
  Divider as MuiDivider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  AlignmentType,
  Document,
  FootnoteReferenceRun,
  HeadingLevel,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TabStopType,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import { Target as TargetIcon } from "react-feather";

import useFetchData from "../../../hooks/useFetchData";
import NumberFormat from "react-number-format";
import LatLongMapDrawdownTool from "../../../components/map/LatLongMapDrawdownTool";
import SearchIcon from "@material-ui/icons/Search";
import { Flex } from "../../../components/Flex";
import SortIcon from "@material-ui/icons/DateRange";
import UpIcon from "@material-ui/icons/ArrowDropUp";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import MaterialTable from "material-table";
import { useApp } from "../../../AppProvider";
import Loader from "../../../components/Loader";
import { formatDate } from "../../../utils/date";
import { THEME } from "../../../constants";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Helmet } from "react-helmet-async";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
const TextField = styled(MuiTextfield)`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
  }
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  height: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 790;
  background-color: ${(props) => props.rgbcolor};
  // color: ${(props) => props.theme.palette.common.white};
`;

function NumberFormatCustom(props) {
  const { config, inputRef, ...other } = props;
  return <NumberFormat getInputRef={inputRef} {...other} />;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    width: 35,
    height: 35,
    fontSize: 15,
  },
  materialTable: {
    "& th:last-child": {
      textAlign: "left!important",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  search: {
    width: "100%",
  },
  list: {
    overflowY: "scroll",
    maxHeight: 587,
    height: 587,
  },
}));

const DrawdownTool = () => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();
  const [Aquifers] = useFetchData(`list-aquifers`, []);
  const [Units] = useFetchData(`list-units`, []);

  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const [RunList] = useFetchData("output-review01-run-list", [refreshSwitch]);

  const [searchText, setSearchText] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [selectedWell, setSelectedWell] = useState({});

  const [runDetails, setRunDetails] = useState({});
  const [runResults, setRunResults] = useState({});

  const divSaveRef = useRef(null);
  const handlePrintMapClick = useReactToPrint({
    content: () => divSaveRef.current,
  });

  useEffect(() => {
    if (Object.keys(selectedWell).length) {
      async function send() {
        try {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };

          const { data: runDetails } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/output-review02-run-details/${selectedWell.run_ndx}`,
            { headers }
          );

          const { data: runResults } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/output-review03-run-results/${selectedWell.run_ndx}`,
            { headers }
          );

          setRunDetails(runDetails);
          setRunResults(runResults);
        } catch (err) {
          // Is this error because we cancelled it ourselves?
          if (axios.isCancel(err)) {
            console.log(`call was cancelled`);
          } else {
            console.error(err);
          }
        }
      }
      send().then();
    }
  }, [selectedWell, refreshSwitch, getAccessTokenSilently]);

  useEffect(() => {
    if (RunList.length > 0) {
      setSelectedWell(RunList[0]);
    }
  }, [RunList]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleSortDirection = () => {
    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
  };

  const defaultSelectionValues = {
    proposed_well_name: null,
    aquifer_ndx: null,
    latitude_dd: "",
    longitude_dd: "",
    distance_radius: null,
    distance_units: null,
    proposed_annual_af: null,
    proposed_rate_gpm: null,
    custom_transmissivity: null,
    custom_storativity: null,
    use_custom_t: false,
    use_custom_s: false,
    run_notes: "",
  };

  const columnsDetails = [
    { title: "Proposed Well Name", field: "proposed_well_name" },
    { title: "Aquifer", field: "source_aquifer" },
    { title: "X", field: "x" },
    { title: "Y", field: "y" },
    { title: "Search (ft)", field: "search_radius_ft" },
    { title: "Annual (af)", field: "annual_af_requested" },
    { title: "Discharge (gpm)", field: "discharge_gpm" },
    {
      title: "Transmissivity Used for Calculations",
      field: "modeled_transmissivity",
    },
    { title: "Source of Transmissivity Value", field: "trans_source" },
    {
      title: "Storativity Used for Calculations",
      field: "modeled_storativity",
    },
    { title: "Source of Storativity Value", field: "stor_source" },
    { title: "Notes", field: "run_notes" },
    {
      title: "Run Time",
      field: "run_timestamp",
      render: (rowData) =>
        formatDate(rowData.run_timestamp, THEME.DATETIME_FORMAT_SHORT),
    },
  ];

  const columnsResults = [
    {
      title: "Well Name",
      field: "well_name",
    },
    {
      title: "Management Zone",
      field: "management_zone",
    },
    { title: "Distance (ft)", field: "distance_ft" },
    { title: "1-Day Drawdown (ft)", field: "drawdown_daily" },
    { title: "30-Day Drawdown (ft)", field: "drawdown_monthly" },
    { title: "1-Year Drawdown (ft)", field: "drawdown_annual" },
  ];

  const [filterValues, setFilterValues] = useState(defaultSelectionValues);
  const changeFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      if (name === "use_custom_t" && value === false) {
        newFilterValues.custom_transmissivity = null;
      }
      if (name === "use_custom_s" && value === false) {
        newFilterValues.custom_storativity = null;
      }
      if (name === "aquifer_ndx") {
        if (Aquifers.find((a) => a.aquifer_ndx === value).custom_required) {
          newFilterValues.use_custom_t = true;
          newFilterValues.use_custom_s = true;
        } else {
          if (
            Aquifers?.find((a) => a.aquifer_ndx === prevState.aquifer_ndx)
              ?.custom_required
          ) {
            if (!newFilterValues.custom_transmissivity) {
              newFilterValues.use_custom_t = false;
            }
            if (!newFilterValues.custom_storativity) {
              newFilterValues.use_custom_s = false;
            }
          }
        }
      }
      return newFilterValues;
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    // Set up a cancellation source
    let didCancel = false;
    setIsLoading(true);
    // setWaitingState("in progress");
    async function writeData() {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        await axios.patch(
          `${process.env.REACT_APP_ENDPOINT}/api/proposed-well`,
          filterValues,
          { headers }
        );
        if (!didCancel) {
          doToast(
            "success",
            "Drawdown projection model successfully processed"
          );
          setRefreshSwitch((state) => !state);
          setIsLoading(false);
        }
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          console.error(err);
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        } else {
          console.error(err);
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        }
        didCancel = true;
        setIsLoading(false);
      }
    }
    writeData().then();
  };

  const handleDelete = (ndx) => {
    let didCancel = false;
    async function deleteData() {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dd99-delete-run-from-log/${ndx}`,
          { headers }
        );
        if (!didCancel) {
          setRefreshSwitch((state) => !state);
          doToast(
            "success",
            "Entry and associated records successfully deleted"
          );
          console.log(data);
        }
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          console.error(err);
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        } else {
          console.error(err);
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        }
        didCancel = true;
      }
    }
    deleteData().then();
  };

  const handleDownload = (ndx) => {
    let didCancel = false;
    async function writeData() {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/output-review04-final-report/${ndx}`,
          { headers }
        );
        if (!didCancel) {
          doToast("success", "Permit analysis report successfully generated");
          generate(data);
        }
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          console.error(err);
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        } else {
          console.error(err);
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        }
        didCancel = true;
      }
    }
    writeData().then();
  };

  const [virtualBoreContent, setVirtualBoreContent] = useState();
  const handleVirtualBore = (lat, lon) => {
    let didCancel = false;
    async function fetchData() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-virtual-bore-data-records/${lat}/${lon}`
        );
        if (!didCancel) {
          doToast("success", "Virtual bore report successfully generated");
          setVirtualBoreContent({
            coordinates: { lat, lon },
            data: data,
          });
        }
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          console.error(err);
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        } else {
          console.error(err);
          const message = err?.message ?? "Something went wrong";
          doToast("error", message);
        }
        didCancel = true;
      }
    }
    fetchData().then();
  };

  useEffect(() => {
    if (virtualBoreContent) {
      handlePrintMapClick();
    }
  }, [virtualBoreContent]); //eslint-disable-line

  const doc = (values) => {
    return new Document({
      styles: {
        default: {
          heading2: {
            run: {
              size: 22,
              bold: false,
              italics: false,
              font: "Calibri (body)",
              // color: "FF0000",
            },
            paragraph: {
              spacing: {
                after: 160,
                line: 260,
              },
            },
          },
          heading3: {
            run: {
              size: 22,
              bold: false,
              italics: false,
              font: "Calibri (body)",
              // color: "FF0000",
            },
            paragraph: {
              spacing: {
                before: 2040,
                line: 260,
              },
            },
          },
        },
      },
      footnotes: {
        1: {
          children: [
            new Paragraph({
              text: " Theis, C.V., 1935, The Relation Between the Lowering of the Piezometric Surface and the Rate and Duration of Discharge of a Well Using Ground-Water Storage: American Geophysical Union Transactions, v. 16, p. 519-524.",
              font: {
                name: "Calibri (body)",
              },
              indent: {
                firstLine: 0,
                left: 400,
                hanging: 400,
              },
            }),
          ],
        },
        2: {
          children: [
            new Paragraph({
              text: " Groundwater availability model (GAM) datasets include the Northern Edwards GAM, the Northern Trinty/Woodbine GAM (for the Upper and Middle Trinity aquifers), and the modified Northern Trinty/Woodbine GAM (for the Lower Trinity Aquifer).",
              font: {
                name: "Calibri (body)",
              },
              indent: {
                firstLine: 0,
                left: 400,
                hanging: 400,
              },
            }),
          ],
        },
      },
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "Proposed Well ID: ",
                  bold: true,
                }),
                new TextRun({
                  text: `${values[0].proposed_well_id}`,
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "Aquifer: ",
                  bold: true,
                }),
                new TextRun({
                  text: `${values[0].aquifer}`,
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "Proposed Annual Production: ",
                  bold: true,
                }),
                new TextRun({
                  text: `${values[0].proposed_annual_production}`,
                  italics: true,
                }),
                new TextRun({
                  text: " Acre-Feet per Year",
                  italics: false,
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "Proposed Instantaneous Pumping Rate: ",
                  bold: true,
                }),
                new TextRun({
                  text: `${values[0].proposed_instantaneous_pumping_rate}`,
                  italics: true,
                }),
                new TextRun({
                  text: " Gallons per Minute",
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "The potential effects of the proposed production on local water levels in the aquifer are calculated using the Theis equation",
                }),
                new FootnoteReferenceRun(1),
                new TextRun({
                  text: " which relates water level decline (that is, drawdown) to the pumping rate of a well and properties of the aquifer. While the equation does not account for aquifer conditions which may affect the calculation of long-term water level declines (for example: aquifer recharge, faulting, or changes in aquifer structure), it does provide a very good, reliable, and straightforward method for estimating relatively short-term drawdown in and near a well due to pumping. To assess the potential effects from the proposed production, the equation uses values from the groundwater availability model datasets",
                }),
                new FootnoteReferenceRun(2),
                new TextRun({
                  text: ".",
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "The following table presents the calculated drawdown at the proposed well and at other nearby wells completed in the same aquifer. For 1-Day Drawdown, we applied the proposed instantaneous pumping rate for a period of 24 hours. For 30-Day Drawdown, we assumed peak pumping during the summer of about 15 percent more than the average monthly amount (that is, the proposed annual production rate divided by 12 then multiplied by 1.15). For 1-Year Drawdown, we used the proposed annual production amount.",
                }),
              ],
            }),
            new Table({
              columnWidths: [1802, 1802, 1802, 1802, 1802],
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      verticalAlign: VerticalAlign.BOTTOM,
                      width: {
                        size: 1802,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: "Well Name",
                              size: 20,
                              bold: true,
                              font: { name: "Calibri (body)" },
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.BOTTOM,
                      width: {
                        size: 1802,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: "Distance from Proposed Well (feet)",
                              size: 20,
                              bold: true,
                              font: { name: "Calibri (body)" },
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.BOTTOM,
                      width: {
                        size: 1802,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: "1-Day Drawdown (feet)",
                              size: 20,
                              bold: true,
                              font: { name: "Calibri (body)" },
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.BOTTOM,
                      width: {
                        size: 1802,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: "30-Day Drawdown (feet)",
                              size: 20,
                              bold: true,
                              font: { name: "Calibri (body)" },
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.BOTTOM,
                      width: {
                        size: 1802,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: "1-Year Drawdown (feet)",
                              size: 20,
                              bold: true,
                              font: { name: "Calibri (body)" },
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),

                ...values.map((item) => {
                  return new TableRow({
                    children: [
                      new TableCell({
                        verticalAlign: VerticalAlign.BOTTOM,
                        width: {
                          size: 1802,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: `${item.well_name}`,
                                size: 20,
                                bold: true,
                                font: { name: "Calibri (body)" },
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableCell({
                        verticalAlign: VerticalAlign.BOTTOM,
                        width: {
                          size: 1802,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text:
                                  item.distance_from_well_ft === 0
                                    ? "---"
                                    : `${item.distance_from_well_ft}`,
                                size: 20,
                                font: { name: "Calibri (body)" },
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableCell({
                        verticalAlign: VerticalAlign.BOTTOM,
                        width: {
                          size: 1802,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: `${item.drawdown_1day}`,
                                size: 20,
                                font: { name: "Calibri (body)" },
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableCell({
                        verticalAlign: VerticalAlign.BOTTOM,
                        width: {
                          size: 1802,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: `${item.drawdown_30day}`,
                                size: 20,
                                font: { name: "Calibri (body)" },
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableCell({
                        verticalAlign: VerticalAlign.BOTTOM,
                        width: {
                          size: 1802,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                text: `${item.drawdown_1year}`,
                                size: 20,
                                font: { name: "Calibri (body)" },
                              }),
                            ],
                          }),
                        ],
                      }),
                    ],
                  });
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "",
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "The predicted drawdown presented above is based on our current understanding of the aquifer hydraulic properties and the estimated production from the proposed well. The predicted drawdown values presented do not include the effects from other wells pumping near the proposed well. Predicted drawdown of less than one (1) foot is considered negligible for analysis purposes due to inherent uncertainty in the aquifer hydraulic characteristics and a difference in the estimated retail electricity costs for a typical domestic well being less than $0.10.",
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_1,
              children: [
                new TextRun({
                  text: "Recommendations",
                  font: {
                    name: "Calibri Light",
                  },
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "To assess actual changes in water levels due to pumping from the proposed well, the well driller should ensure there is a removable plug in the sanitary seal to allow clear access into the well for water level measurement by the District. In addition, if space allows, the pump installer should install a measuring tube alongside the column pipe to allow for measurement of the water level using an e-line or other direct measurement method. Upon discussion and coordination with the District, other automated water level monitoring methods may be considered for installation with the well.",
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_1,
              children: [
                new TextRun({
                  text: "Geoscientist Seal",
                  font: {
                    name: "Calibri Light",
                  },
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "The following licensed professional geoscientist(s) have reviewed the results and recommendations presented in this report of the potential effects due to production from a proposed well.",
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_3,
              children: [
                new TextRun({
                  text: "___________________________________",
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: "Name",
                }),
                new TextRun({
                  text: "\tSeal",
                }),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: 6480,
                },
              ],
            }),
          ],
        },
      ],
    });
  };

  const generate = (values) => {
    Packer.toBlob(doc(values)).then((blob) => {
      saveAs(blob, "example.docx");
    });
  };

  const disableSubmit =
    !filterValues.proposed_well_name ||
    !filterValues.aquifer_ndx ||
    !filterValues.distance_radius ||
    filterValues.distance_radius <= 0 ||
    !filterValues.distance_units ||
    !filterValues.longitude_dd ||
    filterValues.longitude_dd > 180 ||
    filterValues.longitude_dd < -180 ||
    !filterValues.latitude_dd ||
    filterValues.latitude_dd > 90 ||
    filterValues.latitude_dd < -90 ||
    !filterValues.proposed_annual_af ||
    filterValues.proposed_annual_af <= 0 ||
    !filterValues.proposed_rate_gpm ||
    filterValues.proposed_rate_gpm <= 0 ||
    (filterValues.use_custom_t && !filterValues.custom_transmissivity) ||
    (filterValues.use_custom_s && !filterValues.custom_storativity);

  const geologicUnitColors = {
    "Del Rio, Georgetown, Main Street & Paw Paw Limestone": "#E8BA6C",
    "Glen Rose": "#99DEF5",
    "Austin Chalk": "#94CE94",
    "Pearsall & Hammett Shale": "#DFE094",
    Hosston: "#403ECD",
    Walnut: "#097608",
    "Edwards & Commanche Peak Limestone": "#C5FBC7",
    "Hensell & Cow Creek Limestone": "#C454C4",
  };

  const Centered = ({ children }) => {
    return <div style={{ textAlign: "center" }}>{children}</div>;
  };

  return (
    <React.Fragment>
      <Helmet title="Drawdown Tool" />
      <Typography variant="h3" gutterBottom display="inline">
        Drawdown Tool
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Drawdown Tool</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      {/*PROPOSED WELL*/}
      <Card>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <LatLongMapDrawdownTool
                filterValues={filterValues}
                setFilterValues={changeFilterValues}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <TextField
                variant="outlined"
                label="Proposed Well ID"
                style={{ width: "100%" }}
                error={!filterValues.proposed_well_name}
                helperText={
                  !filterValues.proposed_well_name ? "*Required field" : ""
                }
                onChange={(e) =>
                  changeFilterValues("proposed_well_name", e.target.value)
                }
                value={filterValues.proposed_well_name || ""}
              />
            </Grid>

            {/*SOURCE AQUIFER*/}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <TextField
                required
                error={!filterValues.aquifer_ndx}
                helperText={!filterValues.aquifer_ndx ? "*Required field" : ""}
                variant="outlined"
                select
                label="Source Aquifer"
                style={{ width: "100%" }}
                onChange={(e) =>
                  changeFilterValues("aquifer_ndx", e.target.value)
                }
                value={filterValues.aquifer_ndx || ""}
              >
                {Aquifers.map((aquifer) => {
                  return (
                    <MenuItem
                      key={aquifer.aquifer_ndx}
                      value={aquifer.aquifer_ndx}
                    >
                      {aquifer.aquifer_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            {/*MAX SEARCH RESULTS*/}
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <TextField
                name="distance_radius"
                required
                error={
                  !filterValues.distance_radius ||
                  filterValues.distance_radius <= 0
                }
                helperText={
                  !filterValues.distance_radius &&
                  filterValues.distance_radius !== 0
                    ? "*Required field"
                    : filterValues.distance_radius <= 0
                    ? "Must be greater than 0"
                    : ""
                }
                variant="outlined"
                label="Maximum Search Radius"
                style={{ width: "100%" }}
                type="number"
                onChange={(e) =>
                  changeFilterValues("distance_radius", e.target.value)
                }
                value={filterValues.distance_radius || ""}
              />
            </Grid>

            {/*UNITS*/}
            <Grid item xs={12} sm={6} lg={2} xl={3}>
              <TextField
                name="distance_units"
                required
                error={!filterValues.distance_units}
                helperText={
                  !filterValues.distance_units ? "*Required field" : ""
                }
                variant="outlined"
                select
                label="Units"
                style={{ width: "100%" }}
                onChange={(e) =>
                  changeFilterValues("distance_units", e.target.value)
                }
                value={filterValues.distance_units || ""}
              >
                {Units.map((aquifer) => {
                  return (
                    <MenuItem key={aquifer.unit_ndx} value={aquifer.unit_ndx}>
                      {aquifer.unit_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            {/*PROPOSED LOCATION*/}
            {/*X*/}
            <Grid item xs={12} sm={6} lg={2} xl={3}>
              <TextField
                name="longitude_dd"
                required
                error={
                  !filterValues.longitude_dd ||
                  filterValues.longitude_dd > 180 ||
                  filterValues.longitude_dd < -180
                }
                helperText={
                  !filterValues.longitude_dd && filterValues.longitude_dd !== 0
                    ? "*Required field"
                    : filterValues.longitude_dd > 180 ||
                      filterValues.longitude_dd < -180
                    ? "Invalid coordinate"
                    : ""
                }
                variant="outlined"
                label="Long."
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    decimalScale: 5,
                    fixedDecimalScale: true,
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  changeFilterValues("longitude_dd", e.target.value)
                }
                value={filterValues.longitude_dd || ""}
              />
            </Grid>
            {/*PROPOSED LOCATION*/}
            {/*Y*/}
            <Grid item xs={12} sm={6} lg={2} xl={3}>
              <TextField
                name="latitude_dd"
                required
                error={
                  !filterValues.latitude_dd ||
                  filterValues.latitude_dd > 90 ||
                  filterValues.latitude_dd < -90
                }
                helperText={
                  !filterValues.latitude_dd && filterValues.latitude_dd !== 0
                    ? "*Required field"
                    : filterValues.latitude_dd > 90 ||
                      filterValues.latitude_dd < -90
                    ? "Invalid coordinate"
                    : ""
                }
                variant="outlined"
                label="Lat."
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    decimalScale: 5,
                    fixedDecimalScale: true,
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  changeFilterValues("latitude_dd", e.target.value)
                }
                value={filterValues.latitude_dd || ""}
              />
            </Grid>

            {/*ANNUAL PRODUCTION REQUESTED*/}
            {/*Y*/}
            <Grid item xs={12} sm={7} md={6} lg={4} xl={3}>
              <TextField
                name="proposed_annual_af"
                required
                error={
                  !filterValues.proposed_annual_af ||
                  filterValues.proposed_annual_af <= 0
                }
                helperText={
                  !filterValues.proposed_annual_af &&
                  filterValues.proposed_annual_af !== 0
                    ? "*Required field"
                    : filterValues.proposed_annual_af <= 0
                    ? "Must be greater than 0"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">AF</InputAdornment>
                  ),
                }}
                variant="outlined"
                label="Annual Production Requested"
                style={{ width: "100%" }}
                type="number"
                onChange={(e) =>
                  changeFilterValues("proposed_annual_af", e.target.value)
                }
                value={filterValues.proposed_annual_af || ""}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={6} lg={4} xl={3}>
              <TextField
                name="proposed_rate_gpm"
                required
                error={
                  !filterValues.proposed_rate_gpm ||
                  filterValues.proposed_rate_gpm <= 0
                }
                helperText={
                  !filterValues.proposed_rate_gpm &&
                  filterValues.proposed_rate_gpm !== 0
                    ? "*Required field"
                    : filterValues.proposed_rate_gpm <= 0
                    ? "Must be greater than 0"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">GPM</InputAdornment>
                  ),
                }}
                variant="outlined"
                label="Discharge Rate"
                style={{ width: "100%" }}
                type="number"
                onChange={(e) =>
                  changeFilterValues("proposed_rate_gpm", e.target.value)
                }
                value={filterValues.proposed_rate_gpm || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                name="custom_transmissivity"
                required
                error={
                  filterValues.use_custom_t &&
                  !filterValues.custom_transmissivity
                }
                helperText={
                  filterValues.use_custom_t &&
                  !filterValues.custom_transmissivity
                    ? "*Required field"
                    : ""
                }
                disabled={!filterValues.use_custom_t}
                variant="outlined"
                label="Custom Transmissivity"
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    isAllowed: ({ value }) =>
                      value.replace(/,/g, "").length <= 19,
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  changeFilterValues("custom_transmissivity", e.target.value)
                }
                value={filterValues.custom_transmissivity || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterValues.use_custom_t || false}
                    onChange={(e) =>
                      changeFilterValues("use_custom_t", e.target.checked)
                    }
                    name="use_custom_t"
                    color="primary"
                    disabled={
                      Aquifers?.find(
                        (a) => a.aquifer_ndx === filterValues.aquifer_ndx
                      )?.custom_required
                    }
                  />
                }
                label="Use Custom Transmissivity"
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                name="custom_storativity"
                disabled={!filterValues.use_custom_s}
                required
                error={
                  filterValues.use_custom_s && !filterValues.custom_storativity
                }
                helperText={
                  filterValues.use_custom_s && !filterValues.custom_storativity
                    ? "*Required field"
                    : ""
                }
                variant="outlined"
                label="Custom Storativity"
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    isAllowed: ({ value }) =>
                      value.replace(/,/g, "").length <= 19,
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) =>
                  changeFilterValues("custom_storativity", e.target.value)
                }
                value={filterValues.custom_storativity || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterValues.use_custom_s || false}
                    onChange={(e) =>
                      changeFilterValues("use_custom_s", e.target.checked)
                    }
                    name="use_custom_s"
                    color="primary"
                    disabled={
                      Aquifers?.find(
                        (a) => a.aquifer_ndx === filterValues.aquifer_ndx
                      )?.custom_required
                    }
                  />
                }
                label="Use Custom Storativity"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="run_notes"
                variant="outlined"
                label="Notes"
                multiline
                rows={2}
                style={{ width: "100%" }}
                onChange={(e) =>
                  changeFilterValues("run_notes", e.target.value)
                }
                value={filterValues.run_notes || ""}
              />
            </Grid>
            <Hidden smDown>
              <Grid
                item
                md={7}
                lg={8}
                style={{
                  position: "relative",
                }}
              />
            </Hidden>
            <Grid item xs={12} md={5} lg={4}>
              {isLoading ? (
                <Loader />
              ) : (
                <Button
                  // disabled={isSubmitButtonDisabled}
                  style={{ width: "100%", height: "44px" }}
                  type="submit"
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                >
                  Run Drawdown Projection Model
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card mt={4}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={5} md={4} lg={3}>
              {RunList.length === 0 ? (
                <Box marginTop={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Run List
                  </Typography>
                  <Typography variant="body1">No Data Found</Typography>
                </Box>
              ) : (
                <Box marginTop={2}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Run List
                  </Typography>

                  <Flex>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="search">Search</InputLabel>
                      <OutlinedInput
                        autoComplete="off"
                        id="search"
                        value={searchText}
                        onChange={handleSearch}
                        label="Search"
                        className={classes.search}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="search">
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Flex justifyContent="start">
                      <Tooltip title="Sort by Date Processed">
                        <IconButton onClick={handleSortDirection}>
                          <SortIcon color="primary" size="large" />
                        </IconButton>
                      </Tooltip>
                      {sortDirection === "desc" ? (
                        <UpIcon size="small" color="disabled" />
                      ) : (
                        <DownIcon size="small" color="disabled" />
                      )}
                    </Flex>
                  </Flex>
                  <List className={classes.list}>
                    {RunList.filter((record) =>
                      record["proposed_well_name"]
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                      .sort((a, b) => {
                        if (sortDirection === "asc") {
                          if (a["run_ndx"] > b["run_ndx"]) {
                            return 1;
                          }
                          if (a["run_ndx"] < b["run_ndx"]) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (a["run_ndx"] < b["run_ndx"]) {
                            return 1;
                          }
                          if (a["run_ndx"] > b["run_ndx"]) {
                            return -1;
                          }
                          return 0;
                        }
                      })
                      .map((record) => (
                        <Tooltip
                          title={`Originally processed at: ${record["run_time"]}`}
                          key={record["run_ndx"]}
                          arrow
                          placement="bottom"
                        >
                          <ListItem
                            button
                            selected={
                              record["run_ndx"] === selectedWell["run_ndx"]
                            }
                            onClick={() => setSelectedWell(record)}
                          >
                            <ListItemAvatar>
                              <Avatar className={classes.avatar}>
                                {record["proposed_well_name"].charAt(0)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={record["proposed_well_name"]}
                              // secondary={
                              //   <>
                              //     {record[secondaryDisplayField]}
                              //     <br />
                              //     {record[tertiaryDisplayField]} (af)
                              //   </>
                              // }
                            />
                          </ListItem>
                        </Tooltip>
                      ))}
                  </List>
                </Box>
              )}
            </Grid>
            {runDetails && selectedWell?.proposed_well_name && (
              <Grid item xs={7} md={8} lg={9}>
                <Box marginTop={2} width="100%">
                  <MaterialTable
                    title="Run Details"
                    columns={columnsDetails}
                    data={[runDetails]}
                    // isLoading={isLoading}
                    // components={{
                    //   Toolbar: (props) => {
                    //     return (
                    //       <Toolbar
                    //         handleChange={handleExclude}
                    //         checked={exclude}
                    //         {...props}
                    //       />
                    //     );
                    //   },
                    // }}
                    actions={[
                      () => ({
                        icon: "edit",
                        tooltip: "Autofill Drawdown Projection Model Form",
                        // disabled: !rowData.has_production,
                        onClick: (event, rowData) => {
                          setFilterValues({
                            proposed_well_name:
                              rowData.proposed_well_name || null,
                            aquifer_ndx:
                              Aquifers.find(
                                (aquifer) =>
                                  aquifer.aquifer_name ===
                                  rowData.source_aquifer
                              ).aquifer_ndx || null,
                            latitude_dd: rowData.y || "",
                            longitude_dd: rowData.x || "",
                            distance_radius: rowData.search_radius_ft || null,
                            distance_units:
                              Units.find((unit) => unit.unit_name === "Feet")
                                .unit_ndx || null,
                            proposed_annual_af:
                              rowData.annual_af_requested || null,
                            proposed_rate_gpm: rowData.discharge_gpm || null,
                            custom_transmissivity:
                              rowData.modeled_transmissivity ?? null,
                            use_custom_t: !!rowData.modeled_transmissivity,
                            custom_storativity:
                              rowData.modeled_storativity ?? null,
                            use_custom_s: !!rowData.modeled_storativity,
                            run_notes: rowData.run_notes || "",
                          });
                        },
                      }),
                      () => ({
                        icon: "download",
                        tooltip: "Download Permit Analysis Report",
                        // disabled: !rowData.has_production,
                        onClick: (event, rowData) =>
                          handleDownload(rowData.run_ndx),
                      }),
                      () => ({
                        icon: TargetIcon,
                        tooltip: "Download Virtual Bore Report",
                        // disabled: !rowData.has_production,
                        onClick: (event, rowData) =>
                          handleVirtualBore(rowData.y, rowData.x),
                      }),
                      () => ({
                        icon: "delete",
                        tooltip: "Delete Entry and Associated Records",
                        // disabled: !rowData.has_production,
                        onClick: (event, rowData) =>
                          handleDelete(rowData.run_ndx),
                      }),
                    ]}
                    options={{
                      actionsCellStyle: { justifyContent: "center" },
                      pageSize: 1,
                      pageSizeOptions: [1],
                      padding: "dense",
                      search: false,
                      paging: false,
                      // selection: true,
                      // selectionProps: (rowData) => ({
                      //   checked: selections.includes(rowData.well_ndx),
                      // }),
                      // showTextRowsSelected: false,
                      // showSelectAllCheckbox: false,
                    }}
                    // onSelectionChange={onCheck}
                  />
                </Box>
                {runResults?.length > 0 && selectedWell?.proposed_well_name && (
                  <Box
                    marginTop={2}
                    width="100%"
                    className={classes.materialTable}
                  >
                    <MaterialTable
                      title="Run Results"
                      columns={columnsResults}
                      data={runResults}
                      // isLoading={isLoading}
                      // components={{
                      //   Toolbar: (props) => {
                      //     return (
                      //       <Toolbar
                      //         handleChange={handleExclude}
                      //         checked={exclude}
                      //         {...props}
                      //       />
                      //     );
                      //   },
                      // }}
                      options={{
                        actionsCellStyle: { justifyContent: "center" },
                        pageSize: 10,
                        pageSizeOptions: [10, 30, 60],
                        // maxBodyHeight: 600,
                        padding: "dense",
                        // selection: true,
                        // selectionProps: (rowData) => ({
                        //   checked: selections.includes(rowData.well_ndx),
                        // }),
                        // showTextRowsSelected: false,
                        // showSelectAllCheckbox: false,
                      }}
                      // onSelectionChange={onCheck}
                    />
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {virtualBoreContent && (
        <div style={{ display: "none" }}>
          <Paper ref={divSaveRef}>
            <CardContent style={{ width: "100%" }}>
              <Centered>
                <Grid container justify={"space-between"} alignItems={"center"}>
                  <Grid item xs={3} style={{ textAlign: "center" }}>
                    <img
                      src="/static/img/clearwater-logo-full.png"
                      width="150px"
                      alt={"Clearwater Underground Water Conservation District"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h1" style={{ fontWeight: 400 }}>
                      CUWCD Virtual Bore
                    </Typography>
                    <Typography variant="subtitle2" mb={1}>
                      Created: {moment().format("MMMM Do YYYY, h:mma")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "right" }} />
                </Grid>
              </Centered>
              <Divider style={{ marginBottom: "8px" }} />

              <Grid container spacing={6} mb={1}>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" align="right" mr={2}>
                        <strong>Latitude: </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" align="left">
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={virtualBoreContent.coordinates.lat.toFixed(6)}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" align="center">
                        <strong>Approximate Ground Surface Elevation: </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" align="right" mr={2}>
                        <strong>Longitude: </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" align="left">
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={virtualBoreContent.coordinates.lon.toFixed(6)}
                        />
                      </Typography>
                    </Grid>

                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" align="center">
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={
                            virtualBoreContent.data[0]?.surface_elev || "N/A"
                          }
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ marginBottom: "8px" }} />
              <TableContainer>
                {virtualBoreContent?.data?.length > 0 ? (
                  <MuiTable size="small">
                    <TableHead>
                      <MuiTableRow>
                        <MuiTableCell align="center">
                          <strong>Top Elev. (ft)</strong>
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          <strong>Bottom Elev. (ft)</strong>
                        </MuiTableCell>
                        <MuiTableCell align="center"> </MuiTableCell>
                        <MuiTableCell align="center">
                          <strong>Depth to Formation (ft)*</strong>
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          <strong>Formation Thickness (ft)*</strong>
                        </MuiTableCell>
                        <MuiTableCell align="center">
                          <strong>Formation (Geologic Unit)</strong>
                        </MuiTableCell>
                      </MuiTableRow>
                    </TableHead>
                    <TableBody>
                      {virtualBoreContent.data.map((row, i) => (
                        <MuiTableRow key={i}>
                          <MuiTableCell align="center">
                            {row.top_elev}
                          </MuiTableCell>
                          <MuiTableCell align="center">
                            {row.bottom_elev}
                          </MuiTableCell>
                          <MuiTableCell
                            align="center"
                            style={{
                              backgroundColor:
                                geologicUnitColors[row.geologic_unit],
                            }}
                          ></MuiTableCell>
                          <MuiTableCell align="center">
                            {row.depth_to_formation}
                          </MuiTableCell>
                          <MuiTableCell align="center">
                            {row.formation_thickness}
                          </MuiTableCell>
                          <MuiTableCell align="center">
                            {row.geologic_unit}
                          </MuiTableCell>
                        </MuiTableRow>
                      ))}
                    </TableBody>
                  </MuiTable>
                ) : (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <strong>
                      **There is no data available for these coordinates.** —{" "}
                    </strong>
                    please try again!
                  </Alert>
                )}
              </TableContainer>
              <Typography mt={2} mb={2}>
                *Depths / Thicknesses are not to scale
              </Typography>
              <Typography variant="body2">
                <strong>Disclaimer: </strong> This product is for informational
                purposes only and has not been prepared for or suitable for
                legal, engineering, or other purposes. All representations in
                this virtual bore represent only the approximate relative depths
                and thicknesses based on geological interpretation and
                extrapolation of available well data. Additional data may modify
                one or more of these formation surfaces. The Clearwater
                Underground Water Conservation District expressly disclaims any
                and all liability in connection herewith.
              </Typography>
            </CardContent>
          </Paper>
        </div>
      )}
    </React.Fragment>
  );
};

export default DrawdownTool;
