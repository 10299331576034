import React, { useEffect, useMemo, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import { useApp } from "../../../AppProvider";

import MaterialTable from "material-table";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  FormControl as MuiFormControl,
  Grid as MuiGrid,
  MenuItem,
  TextField,
  Button,
  Typography as MuiTypography,
} from "@material-ui/core";

import Panel from "../../../components/panels/Panel";
import Loader from "../../../components/Loader";
import { copyToClipboard, dateFormatter } from "../../../utils";
import NumberFormat from "react-number-format";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

const FormControl = styled(MuiFormControl)`
  width: 100%;
`;

const WaterQuality = ({ data, wellData, currentSelectedPoint }) => {
  const { doToast, lookupTableCache } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRow, setSelectedRow] = useState(null);

  const defaultRowValues = {
    well_ndx: null,
    collected_by_ndx: null,
    tested_by_ndx: null,
    test_datetime: null,
    coliform_presence: null,
    ecoli_presence: null,
    conductivity: null,
    tds: null,
    salinity: null,
    ph: null,
    sodium_ion: null,
    alkalinity: null,
    hardness: null,
    nitrite: null,
    nitrate: null,
    phosphate: null,
    sulfate: null,
    fluoride: null,
    chloride: null,
    calcium: null,
    magnesium: null,
    caco3: null,
    wq_sample_notes: null,
  };

  useEffect(() => {
    if (currentSelectedPoint) {
      setSelectedRow(null);
    }
  }, [currentSelectedPoint]);

  const handleUpdateSelectedRow = (name, value) => {
    const prevState = { ...selectedRow };
    prevState[name] = value;
    setSelectedRow(prevState);
  };

  const [filteredData, setFilteredData] = useState(null);
  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const listWellNdx = useMemo(() => {
    let converted = {};
    if (wellData?.length) {
      wellData.forEach((d) => {
        converted[d.cuwcd_well_number] = d.well_ndx;
      });
    }
    return converted;
  }, [wellData]);

  //LOOKUPS
  const cuwcdLookup = useMemo(() => {
    let converted = {};
    if (data?.length > 0) {
      data.forEach((item) => {
        converted[item.well_ndx] = item.cuwcd_well_number;
      });
    }
    return converted;
  }, [data]);

  const listCollectedBy = useMemo(() => {
    let converted = {};
    if (Object.keys(lookupTableCache).length) {
      lookupTableCache["list_collected_bys"].forEach((d) => {
        if (d.applies_to.includes("wq")) {
          converted[d.collected_by_ndx] = d.collected_by_desc;
        }
      });
    }
    return converted;
  }, [lookupTableCache]);

  const listTestedBy = useMemo(() => {
    let converted = {};
    if (Object.keys(lookupTableCache).length) {
      lookupTableCache["list_tested_bys"].forEach((d) => {
        converted[d.tested_by_ndx] = d.tested_by_desc;
      });
    }
    return converted;
  }, [lookupTableCache]);

  const listWqPresenceAbsence = useMemo(() => {
    let converted = {};
    if (Object.keys(lookupTableCache).length) {
      lookupTableCache["list_wq_presence_absences"].forEach((d) => {
        converted[d.pa_ndx] = d.pa_desc;
      });
    }
    return converted;
  }, [lookupTableCache]);

  const editTableColumns = [
    {
      title: "CUWCD Well Number",
      field: "well_ndx",
      lookup: cuwcdLookup,
    },
    {
      title: "Collected By",
      field: "collected_by_ndx",
      lookup: listCollectedBy,
    },
    {
      title: "Tested By",
      field: "tested_by_ndx",
      lookup: listTestedBy,
    },
    {
      title: "Test Date",
      field: "test_datetime",
      type: "datetime",
      render: (rowData) => {
        return dateFormatter(rowData.test_datetime, "MM/DD/YYYY, h:mm A");
      },
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Ecoli Presence",
      field: "ecoli_presence",
      lookup: listWqPresenceAbsence,
    },
    {
      title: "Coliform Presence",
      field: "coliform_presence",
      lookup: listWqPresenceAbsence,
    },
    {
      title: "pH",
      field: "ph",
      type: "numeric",
      render: (rowData) => {
        return rowData.ph ?? "--";
      },
    },
    {
      title: "Conductivity",
      field: "conductivity",
      type: "numeric",
      render: (rowData) => {
        return rowData.conductivity ?? "--";
      },
    },
    {
      title: "TDS",
      field: "tds",
      type: "numeric",
      render: (rowData) => {
        return rowData.tds ?? "--";
      },
    },
    {
      title: "Salinity",
      field: "salinity",
      type: "numeric",
      render: (rowData) => {
        return rowData.salinity ?? "--";
      },
    },
    {
      title: "Alkalinity",
      field: "alkalinity",
      type: "numeric",
      render: (rowData) => {
        return rowData.alkalinity ?? "--";
      },
    },
    {
      title: "Hardness",
      field: "hardness",
      type: "numeric",
      render: (rowData) => {
        return rowData.hardness ?? "--";
      },
    },
    {
      title: "Nitrite",
      field: "nitrite",
      type: "numeric",
      render: (rowData) => {
        return rowData.nitrite ?? "--";
      },
    },
    {
      title: "Nitrate",
      field: "nitrate",
      type: "numeric",
      render: (rowData) => {
        return rowData.nitrate ?? "--";
      },
    },
    {
      title: "Phosphate",
      field: "phosphate",
      type: "numeric",
      render: (rowData) => {
        return rowData.phosphate ?? "--";
      },
    },
    {
      title: "Sulfate",
      field: "sulfate",
      type: "numeric",
      render: (rowData) => {
        return rowData.sulfate ?? "--";
      },
    },
    {
      title: "Fluoride",
      field: "fluoride",
      type: "numeric",
      render: (rowData) => {
        return rowData.fluoride ?? "--";
      },
    },

    {
      title: "Chloride",
      field: "chloride",
      type: "numeric",
      render: (rowData) => {
        return rowData.chloride ?? "--";
      },
    },
    {
      title: "Calcium",
      field: "calcium",
      type: "numeric",
      render: (rowData) => {
        return rowData.calcium ?? "--";
      },
    },
    {
      title: "Magnesium",
      field: "magnesium",
      type: "numeric",
      render: (rowData) => {
        return rowData.magnesium ?? "--";
      },
    },
    {
      title: "CaCO3",
      field: "caco3",
      type: "numeric",
      render: (rowData) => {
        return rowData.caco3 ?? "--";
      },
    },
    {
      title: "Sodium Ion",
      field: "sodium_ion",
      type: "numeric",
      render: (rowData) => {
        return rowData.sodium_ion ?? "--";
      },
    },
    {
      title: "Notes",
      field: "wq_sample_notes",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
  ];

  const isSubmitButtonDisabled =
    selectedRow === null
      ? true
      : !selectedRow.cuwcd_well_number || !selectedRow.test_datetime;

  const handleSubmit = () => {
    if (selectedRow.ndx) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const submition = selectedRow;
        if (!submition.well_ndx) {
          submition.well_ndx = listWellNdx[currentSelectedPoint];
        }

        const { data: addedEntry } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/dm-wqs`,
          submition,
          { headers }
        );

        //update the material table
        setFilteredData((prevState) => {
          let data = [...prevState];
          if (addedEntry.cuwcd_well_number === currentSelectedPoint) {
            data.unshift(addedEntry);
          }
          return data;
        });
        doToast("success", "New entry was saved to the database");
        //clear the currentRow and removes the form
        setSelectedRow(null);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = () => {
    return (async () => {
      try {
        if (selectedRow) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/dm-wqs/${selectedRow.ndx}`,
            selectedRow,
            { headers }
          );
          //update the material table
          setFilteredData((prevState) => {
            const data = [...prevState];

            data[data.findIndex((item) => item.ndx === selectedRow.ndx)] =
              selectedRow;

            if (selectedRow.cuwcd_well_number !== currentSelectedPoint) {
              return data.filter(
                (item) =>
                  item.cuwcd_well_number !== selectedRow.cuwcd_well_number
              );
            } else {
              return data;
            }
          });
          doToast("success", "New data was updated to the database");
          //clear the currentRow and removes the form
          setSelectedRow(null);
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/dm-wqs/${oldData.ndx}`,
            { headers }
          );
          //update the material table
          setFilteredData((prevState) => {
            const data = [...prevState];
            const index = oldData.tableData.id;
            data.splice(index, 1);
            return data;
          });
          doToast("success", "This entry was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Panel>
            <TableWrapper>
              {filteredData && !Array.isArray(lookupTableCache) ? (
                <MaterialTable
                  id="Well Water Quality Data"
                  title={`Well Water Quality Data ${dateFormatter(
                    new Date(),
                    "MM/DD/YYYY, h:mm A"
                  )}`}
                  columns={editTableColumns}
                  data={filteredData}
                  editable={{
                    onRowDelete: handleDelete,
                  }}
                  localization={{
                    toolbar: { searchPlaceholder: "Search Wells" },
                  }}
                  components={{
                    Container: (props) => <div {...props} />,
                  }}
                  actions={[
                    {
                      icon: CopyIcon,
                      tooltip: "Copy Data",
                      isFreeAction: true,
                      onClick: () => {
                        try {
                          copyToClipboard(filteredData, editTableColumns, () =>
                            doToast(
                              "success",
                              "Data was copied to your clipboard."
                            )
                          );
                        } catch (error) {
                          const message =
                            error?.message ?? "Something went wrong";
                          doToast("error", message);
                        }
                      },
                    },
                    () => ({
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        setSelectedRow(rowData);
                      },
                    }),
                    {
                      icon: () => (
                        <Typography variant="body2">Add Data</Typography>
                      ),
                      tooltip: "Add",
                      isFreeAction: true,
                      onClick: () => {
                        setSelectedRow({
                          ...defaultRowValues,
                          ...{ cuwcd_well_number: currentSelectedPoint },
                          // ...{ ndx: listWellNdx[currentSelectedPoint] },
                        });
                      },
                    },
                  ]}
                  options={{
                    emptyRowsWhenPaging: false,
                    columnsButton: true,
                    exportButton: true,
                    exportAllData: true,
                    addRowPosition: "first",
                    pageSize: 30,
                    pageSizeOptions: [5, 10, 30, 60],
                    padding: "dense",
                    search: false,
                    showTitle: false,
                    maxBodyHeight: "400px",
                  }}
                />
              ) : (
                <Loader />
              )}
            </TableWrapper>
          </Panel>
        </Grid>
      </Grid>

      {selectedRow && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant="h4" ml={2}>
              {!selectedRow.ndx
                ? "New Water Quality Data Entry Form"
                : `Water Quality Data Entry Form for Well: ${selectedRow.cuwcd_well_number}`}
            </Typography>

            <Panel>
              <Grid container spacing={10}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={3}
                  style={{
                    position: "relative",
                  }}
                >
                  {/*<Typography variant="h4">*/}
                  {/*  {selectedRow.cuwcd_well_number}*/}
                  {/*</Typography>*/}
                  <TextField
                    disabled
                    label="CUWCD Well #"
                    onChange={(e) =>
                      handleUpdateSelectedRow(
                        "cuwcd_well_number",
                        e.target.value
                      )
                    }
                    InputProps={{
                      style: {
                        fontSize: "30px",
                        fontWeight: 900,
                        color: "#707070",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    style={{ top: "-7px", width: "100%" }}
                    value={selectedRow.cuwcd_well_number ?? ""}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={3}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    variant="outlined"
                    select
                    label="Collected By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateSelectedRow(
                        "collected_by_ndx",
                        e.target.value
                      )
                    }
                    value={selectedRow.collected_by_ndx ?? ""}
                  >
                    {Object.keys(listCollectedBy).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {listCollectedBy[key]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={3}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    variant="outlined"
                    select
                    label="Tested By"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateSelectedRow("tested_by_ndx", e.target.value)
                    }
                    value={selectedRow.tested_by_ndx ?? ""}
                  >
                    {Object.keys(listTestedBy).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {listTestedBy[key]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={3}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl>
                      <KeyboardDateTimePicker
                        format="LLLL do, yyyy, h:mm a"
                        inputVariant="outlined"
                        autoOk
                        required
                        error={!selectedRow.test_datetime}
                        helperText={
                          !selectedRow.test_datetime ? "Field is Required" : ""
                        }
                        id="test-datetime"
                        label="Test Date/Time"
                        value={selectedRow.test_datetime}
                        onChange={(date) => {
                          handleUpdateSelectedRow("test_datetime", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    variant="outlined"
                    select
                    label="Coliform"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateSelectedRow(
                        "coliform_presence",
                        e.target.value
                      )
                    }
                    value={selectedRow.coliform_presence ?? ""}
                  >
                    {Object.keys(listWqPresenceAbsence).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {listWqPresenceAbsence[key]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("nitrite", null);
                      } else {
                        handleUpdateSelectedRow("nitrite", +e.target.value);
                      }
                    }}
                    value={selectedRow.nitrite ?? ""}
                    decimalScale={3}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Nitrite"
                    customInput={TextField}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    variant="outlined"
                    select
                    label="E Coli"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateSelectedRow("ecoli_presence", e.target.value)
                    }
                    value={selectedRow.ecoli_presence ?? ""}
                  >
                    {Object.keys(listWqPresenceAbsence).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {listWqPresenceAbsence[key]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("nitrate", null);
                      } else {
                        handleUpdateSelectedRow("nitrate", +e.target.value);
                      }
                    }}
                    value={selectedRow.nitrate ?? ""}
                    decimalScale={3}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Nitrate"
                    customInput={TextField}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("conductivity", null);
                      } else {
                        handleUpdateSelectedRow(
                          "conductivity",
                          +e.target.value
                        );
                      }
                    }}
                    value={selectedRow.conductivity ?? ""}
                    decimalScale={0}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Conductivity"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("phosphate", null);
                      } else {
                        handleUpdateSelectedRow("phosphate", +e.target.value);
                      }
                    }}
                    value={selectedRow.phosphate ?? ""}
                    decimalScale={2}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Phosphate"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("tds", null);
                      } else {
                        handleUpdateSelectedRow("tds", +e.target.value);
                      }
                    }}
                    value={selectedRow.tds ?? ""}
                    decimalScale={0}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Total Dissolved Solids (TDS)"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("sulfate", null);
                      } else {
                        handleUpdateSelectedRow("sulfate", +e.target.value);
                      }
                    }}
                    value={selectedRow.sulfate ?? ""}
                    decimalScale={0}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Sulfate"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("salinity", null);
                      } else {
                        handleUpdateSelectedRow("salinity", +e.target.value);
                      }
                    }}
                    value={selectedRow.salinity ?? ""}
                    decimalScale={0}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Salinity"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("fluoride", null);
                      } else {
                        handleUpdateSelectedRow("fluoride", +e.target.value);
                      }
                    }}
                    value={selectedRow.fluoride ?? ""}
                    decimalScale={3}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Fluoride"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("ph", null);
                      } else {
                        handleUpdateSelectedRow("ph", +e.target.value);
                      }
                    }}
                    value={selectedRow.ph ?? ""}
                    decimalScale={2}
                    isAllowed={(inputObj) => {
                      const { value } = inputObj;
                      if (value === "") return true;
                      if (value <= 14 && value >= 1) return true;
                      return false;
                    }}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="pH"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("chloride", null);
                      } else {
                        handleUpdateSelectedRow("chloride", +e.target.value);
                      }
                    }}
                    value={selectedRow.chloride ?? ""}
                    decimalScale={3}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Chloride"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("sodium_ion", null);
                      } else {
                        handleUpdateSelectedRow("sodium_ion", +e.target.value);
                      }
                    }}
                    value={selectedRow.sodium_ion ?? ""}
                    decimalScale={3}
                    // allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Sodium Ion"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("calcium", null);
                      } else {
                        handleUpdateSelectedRow("calcium", +e.target.value);
                      }
                    }}
                    value={selectedRow.calcium ?? ""}
                    decimalScale={3}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Calcium"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("alkalinity", null);
                      } else {
                        handleUpdateSelectedRow("alkalinity", +e.target.value);
                      }
                    }}
                    value={selectedRow.alkalinity ?? ""}
                    decimalScale={0}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Alkalinity"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("magnesium", null);
                      } else {
                        handleUpdateSelectedRow("magnesium", +e.target.value);
                      }
                    }}
                    value={selectedRow.magnesium ?? ""}
                    decimalScale={3}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Magnesium"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("hardness", null);
                      } else {
                        handleUpdateSelectedRow("hardness", +e.target.value);
                      }
                    }}
                    value={selectedRow.hardness ?? ""}
                    decimalScale={0}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="Hardness"
                    customInput={TextField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <NumberFormat
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateSelectedRow("caco3", null);
                      } else {
                        handleUpdateSelectedRow("caco3", +e.target.value);
                      }
                    }}
                    value={selectedRow.caco3 ?? ""}
                    decimalScale={3}
                    allowNegative={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                    label="CaCO3"
                    customInput={TextField}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    position: "relative",
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Notes"
                    multiline
                    rows={2}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUpdateSelectedRow("wq_sample_notes", e.target.value)
                    }
                    value={selectedRow.wq_sample_notes ?? ""}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  style={{
                    position: "relative",
                  }}
                />
                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{
                    position: "relative",
                  }}
                >
                  <Button
                    style={{ width: "100%", height: "44px" }}
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => setSelectedRow(null)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{
                    position: "relative",
                  }}
                >
                  <Button
                    disabled={isSubmitButtonDisabled}
                    style={{ width: "100%", height: "44px" }}
                    type="submit"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Panel>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default WaterQuality;
