import React from "react";
import { Box, ButtonGroup } from "@material-ui/core";
import { schemeCategory10 } from "d3-scale-chromatic";
import styled from "styled-components/macro";
import { adjustOpacity, getContrastColor, hexToRGBA } from "../utils";
import Button from "@material-ui/core/Button";
import ExportCSVButton from "./ExportCSVButton";

const StyledButtonGroup = styled(ButtonGroup)`
  margin: 4px 0 10px 0;
`;

const AnnotationButton = styled(Button)`
  color: ${({ $color }) => getContrastColor($color)};
  background-color: ${({ $color }) => $color};
  &:hover {
    background-color: ${({ $color }) => adjustOpacity($color, 0.9)};
  }
  ${({ $selected, $color }) =>
    $selected &&
    `box-shadow: inset 0 0 10px black; background-color: ${adjustOpacity(
      $color,
      0.9
    )}`}
`;

const AnnotationButtons = ({
  toggleAnnotationVisibility,
  annotationsVisibility,
  well_ndx,
  index,
  getCSVData,
}) => {
  const color = hexToRGBA(schemeCategory10[index % 10], 0.5);

  const handleToggleFormations = () =>
    toggleAnnotationVisibility("formations", well_ndx);
  const handleToggleScreenings = () =>
    toggleAnnotationVisibility("screenings", well_ndx);

  return (
    <Box display="flex" justifyContent="space-between">
      <StyledButtonGroup
        color="primary"
        aria-label={`Control annotations for well ${well_ndx}`}
      >
        <AnnotationButton
          $color={color}
          $selected={annotationsVisibility[`formations_${well_ndx}`]}
          onClick={handleToggleFormations}
        >
          Toggle Formations
        </AnnotationButton>
        <AnnotationButton
          $color={color}
          $selected={annotationsVisibility[`screenings_${well_ndx}`]}
          onClick={handleToggleScreenings}
        >
          Toggle Screenings
        </AnnotationButton>
      </StyledButtonGroup>
      <ExportCSVButton
        getCSVData={getCSVData}
        filename="SignalFire Telemetry"
        wellNdx={well_ndx}
      />
    </Box>
  );
};

export default AnnotationButtons;
