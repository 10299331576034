import React, { useMemo } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import WellDetails from "./WellDetails";
import styled from "styled-components/macro";
import AnnotationButtons from "./AnnotationButtons";

const StyledCard = styled(Card)`
  width: 100%;
`;

const StyledGridItem = styled(Grid)`
  display: flex;
`;

const StyledCardContent = styled(CardContent)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const WellCards = ({
  getCSVData,
  metaData,
  toggleAnnotationVisibility,
  annotationsVisibility,
}) => {
  const wellCards = useMemo(
    () =>
      Object.values(metaData).map(({ well_ndx }, index) => (
        <StyledGridItem key={well_ndx} item xs={12} sm={6} xl={4}>
          <StyledCard>
            <StyledCardContent>
              <WellDetails metaData={metaData} well={well_ndx} />
              <AnnotationButtons
                getCSVData={getCSVData}
                toggleAnnotationVisibility={toggleAnnotationVisibility}
                annotationsVisibility={annotationsVisibility}
                well_ndx={well_ndx}
                index={index}
              />
            </StyledCardContent>
          </StyledCard>
        </StyledGridItem>
      )),
    [metaData, annotationsVisibility, toggleAnnotationVisibility, getCSVData]
  );

  return (
    <Grid container spacing={3}>
      {wellCards}
    </Grid>
  );
};

export default WellCards;
