import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ClickAwayListener,
  Divider,
  InputAdornment,
  List,
  Grid,
  ListItem,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Fuse from "fuse.js";
import useDebounce from "../../../../hooks/useDebounce";
import styled from "styled-components/macro";

const CustomSearch = styled(TextField)`
  fieldset {
    border-radius: 15px;
  }
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 10px;
  left: 260px;
  z-index: 1;
  &:hover {
    background-color: white;
  }
`;
const SearchResults = ({
  anchorEl,
  open,
  onClose,
  onSelect,
  searchResults,
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{ zIndex: 3 }}
      transition
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper style={{ width: 400, height: 384, overflowY: "auto" }}>
          <List dense component="nav" aria-label="main mailbox folders">
            {searchResults?.slice(0, 49)?.map((result, i) => (
              <React.Fragment key={i}>
                <ListItem
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  button
                  onClick={() => onSelect(result?.item)}
                >
                  <Typography variant="subtitle1">
                    {result?.item?.cuwcd_well_number}
                  </Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="caption">Name</Typography>
                      <Typography variant="body1">
                        {result?.item?.well_name || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">State Well #</Typography>
                      <Typography variant="body1">
                        {result?.item?.state_well_number || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">Well Owner</Typography>
                      <Typography variant="body1">
                        {result?.item?.well_owner || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">Well Contact</Typography>
                      <Typography variant="body1">
                        {result?.item?.well_contact || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

const FuzzySearch = ({ onSelect, options }) => {
  const searchRef = useRef(null);

  const [value, setValue] = useState("");
  const debouncedSearchValue = useDebounce(value, 200);
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!searchResults?.length);
  }, [searchResults]);

  const fuzzySearcher = useMemo(() => {
    if (options?.length) {
      return new Fuse(options, {
        ignoreLocation: true,
        keys: [
          "cuwcd_well_number",
          "well_name",
          "state_well_number",
          "well_owner",
          "well_contact",
        ],
      });
    }
  }, [options]);

  const handleClose = (event) => {
    if (searchRef.current && searchRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };

  useEffect(() => {
    const results = fuzzySearcher && fuzzySearcher.search(debouncedSearchValue);
    setSearchResults(results);
  }, [debouncedSearchValue, fuzzySearcher]);

  return (
    <>
      <CustomSearch
        id="outlined-basic"
        label="Individual Well Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        onFocus={() => !!value && setOpen(true)}
        placeholder="Search by well attributes"
        ref={searchRef}
        style={{ width: "calc(100% - 310px)" }}
        type="search"
        value={value}
        variant="outlined"
        size="small"
      />
      <SearchResults
        anchorEl={searchRef?.current}
        onClose={handleClose}
        onSelect={onSelect}
        open={open}
        searchResults={searchResults}
      />
    </>
  );
};

export default FuzzySearch;
