import { Grid, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { QUICKSET_OPTIONS } from "../constants";

export const QuickSetButton = ({ option, setFilters }) => {
  const handleButtonClick = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: option.calculateStartDate(),
      endDate: new Date(),
    }));
  };

  return (
    <Grid item>
      <Tooltip title="Quick set dates">
        <Button
          onClick={handleButtonClick}
          type="submit"
          color="primary"
          variant="contained"
          size="small"
          aria-label={option.label}
        >
          {option.label}
        </Button>
      </Tooltip>
    </Grid>
  );
};
const QuickSetButtons = ({ setInputState }) => {
  return (
    <Grid container spacing={2}>
      {QUICKSET_OPTIONS.map((option, i) => (
        <QuickSetButton key={i} option={option} setFilters={setInputState} />
      ))}
    </Grid>
  );
};

export default QuickSetButtons;
