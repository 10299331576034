import React, { memo } from "react";
import {
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography,
  Paper as MuiPaper,
  Breadcrumbs as MuiBreadcrumbs,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import SelectControl from "../../reports/signalFireTelemetry/components/inputs/SelectControl";
import OptionsHeader from "../../reports/signalFireTelemetry/components/OptionsHeader";
import useSearchPage from "./useSearchPage";
import { TriStateCheckbox } from "../../reports/signalFireTelemetry/components/inputs/CheckBoxes";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import ParameterSelect from "./ParameterSelect";
import ParameterInput from "./ParameterInput";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);

const ActionButton = styled(Button)`
  height: 52px;
`;

const CountValue = styled("span")`
  font-weight: bold;
  font-size: 2rem;
`;

const SearchPage = () => {
  const {
    inputs,
    currentCount,
    inputState,
    handleInputState,
    isFormValid,
    handleCreateReport,
    handleReset,
    addParameter,
    removeParameter,
    updateParameterValue,
    availableParameters,
  } = useSearchPage();

  return (
    <>
      <Helmet title="Water Quality Records Search" />
      <Typography variant="h3" gutterBottom display="inline">
        Water Quality Records Search
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Water Quality Records Search</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper p={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OptionsHeader text="Time Period" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectControl
              label="From Fiscal Year"
              id="startYear"
              valueField="fiscal_year"
              displayField="fiscal_year"
              value={inputState.startYear ?? ""}
              onChange={handleInputState}
              options={inputs.fiscalYears.data}
              isLoading={inputs.fiscalYears.isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectControl
              label="To Fiscal Year"
              id="endYear"
              valueField="fiscal_year"
              displayField="fiscal_year"
              value={inputState.endYear ?? ""}
              onChange={handleInputState}
              options={inputs.fiscalYears.data}
              isLoading={inputs.fiscalYears.isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OptionsHeader text="Selection Criteria" />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TriStateCheckbox
              name="isExempt"
              label="Is Exempt"
              value={inputState.isExempt}
              onChange={handleInputState}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TriStateCheckbox
              name="isActive"
              label="Is Active"
              value={inputState.isActive}
              onChange={handleInputState}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectControl
              label="Well Uses"
              id="wellUses"
              multiple
              valueField="use_ndx"
              displayField="use_desc"
              value={inputState.wellUses ?? []}
              onChange={handleInputState}
              options={inputs.wellUses.data}
              isLoading={inputs.wellUses.isLoading}
              showSelectButtons
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectControl
              label="Aquifer"
              id="aquifers"
              multiple
              valueField="aquifer_ndx"
              displayField="aquifer_name"
              value={inputState.aquifers ?? []}
              onChange={handleInputState}
              options={inputs.aquifers.data}
              isLoading={inputs.aquifers.isLoading}
              showSelectButtons
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectControl
              label="Well Status"
              id="wellStatuses"
              multiple
              valueField="well_status_ndx"
              displayField="well_status_desc"
              value={inputState.wellStatuses ?? []}
              onChange={handleInputState}
              options={inputs.wellStatuses.data}
              isLoading={inputs.wellStatuses.isLoading}
              showSelectButtons
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectControl
              label="Management Zone"
              id="managementZones"
              multiple
              valueField="management_zone"
              displayField="management_zone"
              value={inputState.managementZones ?? []}
              onChange={handleInputState}
              options={inputs.managementZones.data}
              isLoading={inputs.managementZones.isLoading}
              showSelectButtons
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <OptionsHeader text="Optional Parameters" />
          </Grid>
          <Grid item xs={12}>
            <ParameterSelect
              availableParameters={availableParameters}
              addParameter={addParameter}
            />
          </Grid>
          <Grid item xs={12}>
            {inputState.parameters.map((param, index) => (
              <ParameterInput
                key={param.key}
                parameter={param}
                index={index}
                updateParameterValue={updateParameterValue}
                removeParameter={removeParameter}
                options={param.type === "multiselect" ? inputs[param.key] : []}
              />
            ))}
          </Grid>

          <Grid item xs={12} mt={8}>
            <Box display="flex" alignItems="center">
              <Typography>
                {currentCount !== null ? (
                  <>
                    <CountValue>{currentCount}</CountValue> records available
                    for download
                  </>
                ) : (
                  "Please fill out the form to see available records"
                )}
              </Typography>
            </Box>
          </Grid>

          <Grid container spacing={3} mt={2} alignItems="center">
            <Grid item xs>
              <ActionButton
                fullWidth
                onClick={handleCreateReport}
                color="secondary"
                variant="contained"
                disabled={!isFormValid}
              >
                Create Report
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                onClick={handleReset}
                color="secondary"
                variant="outlined"
                style={{ width: "86px" }}
              >
                Clear
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default memo(SearchPage);
