import { dateFormatter } from "../../../../utils";
import { DATE_TIME_FORMAT } from "./constants";

export const matchesFiscalYear = (record, startYear, endYear) =>
  record.fiscal_year >= startYear && record.fiscal_year <= endYear;

export const matchesStatus = (record, field, status) =>
  status === null || record[field] === status;

export const matchesArrayField = (recordField, filterValues) => {
  // Exclude nulls if the parameter is selected, regardless of filter values
  if (recordField === null) {
    return false;
  }
  return filterValues.length === 0 || filterValues.includes(recordField);
};

export const matchesMultiField = (recordField, filterValues) =>
  filterValues.length === 0 ||
  filterValues.some((val) => recordField.includes(val));

export const matchesParameterRange = (record, paramKey, min, max) => {
  const value = record[paramKey];
  // Exclude records where the value is null when the parameter is engaged
  if (value === null) {
    return false;
  }
  const numericValue = parseFloat(value);
  const minValue = min !== "" ? parseFloat(min) : -Infinity;
  const maxValue = max !== "" ? parseFloat(max) : Infinity;
  return numericValue >= minValue && numericValue <= maxValue;
};

// checks to see if form is filled out to allow count fetch
export const hasEmptyValues = (values, excludeKeys = []) => {
  return Object.entries(values).some(([key, value]) => {
    if (excludeKeys.includes(key)) return false;
    if (Array.isArray(value)) return value.length === 0;
    if (typeof value === "string") return value.trim() === "";
    return false;
  });
};

export const getLookupForDataSortedByField = (data, field) => {
  if (!data) return {};

  const uniqueValues = [...new Set(data.map((item) => item[field]))].sort();
  const lookupObject = uniqueValues.reduce((lookup, value) => {
    lookup[value] = value;
    return lookup;
  }, {});

  return lookupObject;
};

export const parseQueryParams = (search) => {
  const queryParams = new URLSearchParams(search);
  const params = { parameters: {} };

  queryParams.forEach((value, key) => {
    if (
      ["wellUses", "managementZones", "aquifers", "wellStatuses"].includes(key)
    ) {
      // Convert comma-separated values into an array of numbers or strings
      params[key] = value
        .split(",")
        .map((item) => (isNaN(item) ? item : Number(item)));
    } else if (["startYear", "endYear"].includes(key)) {
      // Convert fiscal years directly into numbers
      params[key] = Number(value);
    } else if (["isActive", "isExempt"].includes(key) && value !== "null") {
      // Convert boolean strings to actual boolean values
      params[key] = value === "true";
    } else if (key.includes("_min") || key.includes("_max")) {
      // Handle parameter range keys by splitting them into their parameter and range type
      const parameterKey = key.split("_")[0];
      const rangeType = key.split("_")[1];

      // Ensure an object exists for each parameter and set the range type (min or max)
      if (!params.parameters[parameterKey]) {
        params.parameters[parameterKey] = {};
      }
      params.parameters[parameterKey][rangeType] = parseFloat(value);
    } else {
      // Assign all other values directly
      params[key] = value;
    }
  });

  return params;
};

export const transformTestDatetime = (data) =>
  data.map((record) => ({
    ...record,
    test_datetime: dateFormatter(record.test_datetime, DATE_TIME_FORMAT),
  }));

//limits an action to x ms
export const throttle = (fn, wait) => {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      fn.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= wait) {
          fn.apply(context, args);
          lastRan = Date.now();
        }
      }, wait - (Date.now() - lastRan));
    }
  };
};
