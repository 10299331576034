import PrintRefButton from "../../../../components/graphs/PrintRefButton";
import {
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import moment from "moment";
import Link from "@material-ui/core/Link";
import React, { memo, useRef } from "react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);

const Root = styled("div")`
  box-shadow: 0 5px 15px 10px rgba(0, 0, 0, 0.35);
`;

const Page = styled(Paper)`
  page-break-after: always;
  position: relative;
  padding: 25px 50px;
`;

const PageHeader = () => (
  <Grid
    container
    justify="space-between"
    alignItems="center"
    style={{ textAlign: "center" }}
  >
    <Grid item xs={4}>
      <img
        src="/static/img/clearwater-logo-full.png"
        width="200px"
        alt="Clearwater Underground Water Conservation District"
      />
    </Grid>
    <Grid item xs={8}>
      <Typography variant="h3" style={{ fontWeight: 400 }}>
        Water Quality Assessment Results
      </Typography>
      <Typography variant="subtitle2" mb={1}>
        Created: {moment().format("MMMM Do YYYY, h:mma")}
      </Typography>
    </Grid>
    <Grid item xs={3} style={{ textAlign: "right" }} />
  </Grid>
);

const ExternalLink = styled(Link).attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})``;

const NameTypography = styled(Typography).attrs({
  variant: "body2",
})`
  line-height: 1.3;
`;

const ParameterTypography = styled(Typography).attrs({
  variant: "body2",
})`
  font-weight: 900;
  line-height: 1.8;
`;

const FootnoteGridItem = styled(Grid).attrs({
  item: true,
})`
  line-height: 1.3;
  margin-bottom: 7px;
`;

const FootnoteTypography = styled(Typography).attrs({
  variant: "caption",
})`
  line-height: 1.3;
`;

const ParameterDescriptionTypography = styled(Typography).attrs({
  variant: "body2",
})`
  line-height: 1.4;
  margin-bottom: 8px;
`;

const PageFooter = () => (
  <Box
    display="flex"
    justifyContent="space-between"
    style={{ borderTop: "3px solid black" }}
  >
    <Typography variant="body2">
      <strong>P.O. Box 1989</strong>
    </Typography>
    <Typography variant="body2">
      <strong>Belton TX 76513</strong>
    </Typography>
    <Typography variant="body2">
      <strong>Phone: 254-933-0120 </strong>
    </Typography>
    <Typography variant="body2">
      <strong>Fax: 254/933-8396</strong>
    </Typography>
    <Typography variant="body2">
      <ExternalLink href="https://cuwcd.org/">
        <strong>www.cuwcd.org</strong>
      </ExternalLink>{" "}
    </Typography>
  </Box>
);

const Report = ({ selectedPDF }) => {
  const pdfRef = useRef(null);

  const GenerateNameField = ({ label, labelCols, value, valueCols }) => (
    <>
      <Grid item xs={labelCols}>
        <NameTypography>{label}:</NameTypography>
      </Grid>
      <Grid item xs={valueCols}>
        <NameTypography>{value}</NameTypography>
      </Grid>
    </>
  );

  const GenerateParameter = ({ label, result, standard }) => (
    <>
      <Grid item xs={5}>
        <ParameterTypography>
          <u>{label}</u>
        </ParameterTypography>
      </Grid>
      <Grid item xs={2}>
        <ParameterTypography>{result}</ParameterTypography>
      </Grid>
      <Grid item xs={5}>
        <ParameterTypography>{standard}</ParameterTypography>
      </Grid>
    </>
  );

  const GenerateParameterDescription = ({ label, description }) => (
    <ParameterDescriptionTypography>
      <strong>{label} - </strong> {description}
    </ParameterDescriptionTypography>
  );

  return (
    <Root ref={pdfRef}>
      <Page>
        <PrintRefButton
          data-html2canvas-ignore
          ref={pdfRef}
          title="PDF Print"
          style={{ position: "absolute", top: "5px", right: "5px" }}
        />
        <PageHeader />
        <Typography variant="body2">
          The Clearwater Underground Water Conservation District (CUWCD or
          District) provides in-house screening for some of the most common
          parameters for drinking water.{" "}
          <strong>
            Please note that the CUWCD LAB IS NOT A CERTIFIED LABORATORY.
          </strong>{" "}
          This screening is offered as a convenience to registered well owners
          in Bell County and is provided for informative purposes only.
          <u>
            {" "}
            The District disclaims any liability for this screening and the
            accuracy of any analysis. The water quality analytical results from
            different faucets, taken at different times or analyzed by a
            certified laboratory may be different from CUWCD analysis of water
            from the same well.
          </u>{" "}
          Please contact our office if you would like information regarding
          laboratories that are certified for chemical/microbiological testing
          of drinking water.
        </Typography>

        <Divider mt={1} mb={1} />

        <Grid container alignItems="center" mb={1}>
          {[
            {
              label: "Name",
              labelCols: 2,
              value: selectedPDF.well_owner,
              valueCols: 5,
            },
            {
              label: "Phone Number",
              labelCols: 2,
              value: selectedPDF.owner_phone_number,
              valueCols: 3,
            },
            {
              label: "Mailing Address",
              labelCols: 2,
              value: selectedPDF.owner_mailing_address,
              valueCols: 5,
            },
            {
              label: <u>Email</u>,
              labelCols: 2,
              value: selectedPDF.owner_email,
              valueCols: 3,
            },
            {
              label: "CUWCD Well Number",
              labelCols: 3,
              value: selectedPDF.cuwcd_well_number,
              valueCols: 4,
            },
            {
              label: <u>Aquifer</u>,
              labelCols: 2,
              value: selectedPDF.aquifer_name,
              valueCols: 3,
            },
          ].map(({ label, labelCols, value, valueCols }, index) => (
            <GenerateNameField
              key={index}
              label={label}
              labelCols={labelCols}
              value={value}
              valueCols={valueCols}
            />
          ))}
        </Grid>

        <Grid
          container
          alignItems="center"
          style={{ border: "solid 2px black", paddingLeft: "8px" }}
          mb="7px"
        >
          <Grid item xs={7}>
            <ParameterTypography>PARAMETER RESULTS</ParameterTypography>
          </Grid>
          <Grid item xs={2}>
            <ParameterTypography>
              <u>Test Date: </u>
            </ParameterTypography>
          </Grid>
          <Grid item xs={3}>
            <ParameterTypography>
              {selectedPDF.test_datetime}
            </ParameterTypography>
          </Grid>

          <Grid item xs={3}>
            <ParameterTypography style={{ textAlign: "right" }}>
              <u>Coliform Bacteria </u>
            </ParameterTypography>
          </Grid>
          <Grid item xs={9}>
            <ParameterTypography style={{ marginLeft: "30px" }}>
              {selectedPDF.coliform}
            </ParameterTypography>
          </Grid>

          <Grid item xs={3}>
            <ParameterTypography style={{ textAlign: "right" }}>
              <u>Ecoli </u>
            </ParameterTypography>
          </Grid>
          <Grid item xs={9}>
            <ParameterTypography style={{ marginLeft: "30px" }}>
              {selectedPDF.ecoli}
            </ParameterTypography>
          </Grid>

          <Grid item xs={5} />
          <Grid item xs={2}>
            <ParameterTypography style={{ marginLeft: "-30px" }}>
              Results
            </ParameterTypography>
          </Grid>
          <Grid item xs={5}>
            <ParameterTypography style={{ marginLeft: "-30px" }}>
              Drinking Water Standard *
            </ParameterTypography>
          </Grid>

          {[
            {
              label: "Conductivity (µS/cm)",
              result: selectedPDF.conductivity,
              standard: "none",
            },
            {
              label: "**Total Dissolved Solids (mg/L)",
              result: selectedPDF.tds,
              standard: "1,000 mg/L (secondary)",
            },
            {
              label: "Salinity (mg/L)",
              result: selectedPDF.salinity,
              standard: "500 mg/L (secondary)",
            },
            {
              label: "pH",
              result: selectedPDF.ph,
              standard: "6.5 - 8.5 (secondary)",
            },
            {
              label: "Sodium Ion (mg/L)",
              result: selectedPDF.sodium_ion,
              standard: "none",
            },
            {
              label: "Alkalinity (as CaCO3)",
              result: selectedPDF.alkalinity,
              standard: "none",
            },
            {
              label: "Hardness (as CaCO3)",
              result: selectedPDF.hardness,
              standard: "none",
            },
            {
              label: "Nitrite (as N)(mg/L)",
              result: selectedPDF.nitrite,
              standard: "1 mg/L (primary)",
            },
            {
              label: "Nitrate (as N)(mg/L)",
              result: selectedPDF.nitrate,
              standard: "10 mg/L (primary)",
            },
            {
              label: "Phosphate (mg/L)",
              result: selectedPDF.phosphate,
              standard: "none",
            },
            {
              label: "Sulfate (mg/L)",
              result: selectedPDF.sulfate,
              standard: "300 mg/L (secondary)",
            },
            {
              label: "Fluoride (mg/L)",
              result: selectedPDF.fluoride,
              standard: "4.0 mg/L (primary)",
            },
            {
              label: "Chloride (mg/L)",
              result: selectedPDF.chloride,
              standard: "250 mg/L (secondary)",
            },
            {
              label: "Calcium (mg/L)",
              result: selectedPDF.calcium,
              standard: "none",
            },
            {
              label: "Magnesium (mg/L)",
              result: selectedPDF.magnesium,
              standard: "none",
            },
            {
              label: "Calcium Carbonate (CaCO3)",
              result: selectedPDF.caco3,
              standard: "none",
            },
          ].map(({ label, result, standard }, index) => (
            <GenerateParameter
              key={index}
              label={label}
              result={result}
              standard={standard}
            />
          ))}

          <Grid item xs={12}>
            <Typography variant="body2" mb={1}>
              <u>Comments</u>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" mb={1} style={{ lineHeight: 1 }}>
              {selectedPDF.comments}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mb={1}>
          <FootnoteGridItem xs={12}>
            <FootnoteTypography>
              *{" "}
              <em>
                The concentrations of analytical parameters in milligrams per
                liter (mg/L) refer to the Drinking Water Standards for public
                water supply systems established by the United States
                Environmental Protection Agency (EPA) and Texas Commission on
                Environmental Quality (TCEQ). Primary standards are the
                enforceable maximum allowable concentration for each parameter
                to maintain health. Secondary standards are non-enforceable
                guidelines for the cosmetic or esthetic quality of drinking
                water. These standards do not apply to private water wells but
                are useful in assessing water quality. Details on EPA and TCEQ
                drinking water standards are available at:{" "}
              </em>
              <ExternalLink href="http://www.epa.gov/safewater/mcl.html#mcls">
                http://www.epa.gov/safewater/mcl.html#mcls
              </ExternalLink>{" "}
              &{" "}
              <ExternalLink href="http://www.tnrcc.state.tx.us/oprd/rules/pdflib/290_ind.pdf">
                http://www.tnrcc.state.tx.us/oprd/rules/pdflib/290_ind.pdf{" "}
              </ExternalLink>
            </FootnoteTypography>
          </FootnoteGridItem>
          <FootnoteGridItem xs={12}>
            <FootnoteTypography>
              ** The Total Dissolved Solids value reported is calculated from
              the Conductivity measured in the analysis. This TDS value should
              be considered as an “apparent” value and may have limited accuracy
              when compared to values reported by certified laboratories,
              accuracy range may be + or - 25 percent.
            </FootnoteTypography>
          </FootnoteGridItem>
        </Grid>

        <PageFooter />
      </Page>
      <Page>
        <PageHeader />
        <div>
          <ParameterDescriptionTypography>
            The parameters available for analysis are described for information
            purposes. District Staff will analysis all of the following
            parameters. District provides ongoing screening “free of charge” for
            all registered wells in the District upon request.*
          </ParameterDescriptionTypography>

          {[
            {
              label: "Coliform Bacteria",
              description:
                "A family of bacteria common in soils, plants and animals. The presence/absence test only indicates if coliform bacteria are present. No distinction is made on the origin of the coliform bacteria. A positive result warrants further analysis, an inspection of the well integrity and well/water system disinfection. Coliform bacteria should not be present under the federal drinking water standard.",
            },
            {
              label: "Conductivity",
              description:
                "Conductivity measures the ability of water to conduct an electric current and is useful to quickly assess water quality. Conductivity increases with the number of dissolved ions in the water but is affected by temperature and the specific ions in solution. High conductivity or large changes may warrant further analysis. There is no EPA or TCEQ drinking water standard for conductivity.",
            },
            {
              label: "Total Dissolved Solids (TDS)",
              description:
                "Refers to dissolved minerals (ions) and is a good general indicator of water quality. The value reported for this parameter is calculated by the conductivity meter as a function of the conductivity value and may not account for all the factors affecting the Conductivity-TDS relationship. TDS values reported by CUWCD should be considered as “apparent”. The accuracy may range approximately +/- 25 percent from values reported by certified laboratories. The TCEQ secondary drinking water standard for TDS is 1000 mg/L. Water is considered fresh if TDS is 1000 mg/L or less",
            },
            {
              label: "Salinity",
              description:
                "Salinity is the amount of salts that are dissolved in groundwater, and is similar to the Total Dissolved Solids (TDS). The TCEQ secondary drinking water standard if 500 mg/L.",
            },
            {
              label: "pH",
              description:
                "The pH of water is a measure of the concentration of hydrogen ions. pH is expressed on a scale from 1 to 14, with 1 being most acidic, 7 neutral and 14 being the most basic or alkaline. The pH of drinking water should be between 6.5 and 8.5 to meet the federal secondary drinking water standard.",
            },
            {
              label: "Sodium Ion",
              description:
                "For most people, sodium in water does not present a substantial health risk, because the level obtained from water is much less than from the diet. However, certain individuals may be placed on low sodium diets (<1500 mg/d) due to heart, kidney, or blood pressure conditions. There is no EPA or TCEQ drinking water standard for Sodium.",
            },
            {
              label: "Alkalinity (as CaCO3)",
              description:
                "Alkalinity does not refer to pH, but instead refers to the ability of water to resist change in pH and may be due to dissolved bicarbonates. Low water alkalinity may cause corrosion; high alkalinity may cause scale formation. There is no EPA or TCEQ drinking water standard for alkalinity.",
            },
            {
              label: "Hardness (as CaCO3)",
              description:
                "“Hard” water may be indicated by large amounts of soap required to form suds and scale deposits in pipes and water heaters. Hardness is caused by calcium, magnesium, manganese or iron in the form of bicarbonates, carbonates, sulfates or chlorides.",
            },
            {
              label: "Nitrate/Nitrite",
              description:
                "Nitrate and Nitrite are of special concern to infants and can cause “blue baby” syndrome. The federal drinking water standard for nitrate is 10 mg/L. The federal drinking water standard for nitrite is 1 mg/L. Nitrate or nitrite may indicate an impact from sewage, fertilizer or animal waste.",
            },
            {
              label: "Phosphate",
              description:
                "Phosphates may indicate impact from laundering agents. Testing for phosphates provides a general indicator of water quality. There is no EPA or TCEQ drinking water standard for phosphate.",
            },
            {
              label: "Sulfate",
              description:
                "Sulfate compounds are many of the dissolved salts found in groundwater. Sulfate can produce laxative effects, bad taste or smell. The TCEQ secondary drinking water standard for sulfate is 300 mg/L.",
            },
            {
              label: "Fluoride",
              description:
                "Fluoride may occur naturally and is sometimes added to drinking water to promote strong teeth. Fluoride may stain children’s teeth. The federal drinking water standard for fluoride is 4.0 mg/L.",
            },
          ].map(({ label, description }, index) => (
            <GenerateParameterDescription
              key={index}
              label={label}
              description={description}
            />
          ))}
        </div>
        <div style={{ height: "50px" }} />
        <PageFooter />
      </Page>
      <Page>
        <PageHeader />
        <div>
          {[
            {
              label: "Chloride",
              description:
                "Consuming drinking water containing chloride is not harmful to health. High amounts of chloride can give a salty taste to water and can corrode pipes, pumps, and plumbing fixtures. The TCEQ secondary drinking water standard for Chloride is 250 mg/L.",
            },
            {
              label: "Calcium",
              description:
                "Calcium naturally occurs in water, which dissolves it from soils. It is an essential nutrient and is considered beneficial to health, but most calcium comes from the diet. There is no EPA or TCEQ drinking water standard for Calcium.",
            },
            {
              label: "Magnesium",
              description:
                "Magnesium naturally occurs in water, which dissolves it from soils or dolomite rock. It is an essential nutrient and is considered beneficial to health, but most magnesium comes from the diet. There is no EPA or TCEQ drinking water standard for Magnesium.",
            },
            {
              label: "Calcium Carbonate (CaCO3)",
              description:
                "CaCO3 is used as the measurement of hardness in water. 0-60 mg/L is considered soft, 61-120 mg/L is moderately hard, 121-180 mg/L is hard, and more than 180 mg/L is very hard. Hard water can cause mineral buildup in plumbing, fixtures, and water heaters and contributes to poor performance of soaps and detergents. There is no EPA or TCEQ drinking water standard for Calcium Carbonate.",
            },
          ].map(({ label, description }, index) => (
            <GenerateParameterDescription
              key={index}
              label={label}
              description={description}
            />
          ))}
        </div>

        <Grid container mb={3}>
          <FootnoteGridItem xs={12}>
            <FootnoteTypography>
              <em>
                <strong>*Note:</strong> The concentrations of analytical
                parameters in milligrams per liter (mg/L) given below refer to
                the Federal Drinking Water Standards for public water supply
                systems established by the United States Environmental
                Protection Agency (EPA). The EPA has established primary and
                secondary standards for drinking water. Primary standards are
                the enforceable maximum allowable concentration for each
                parameter to maintain health. Secondary standards are non
                -enforceable guidelines for the cosmetic or esthetic quality of
                drinking water. These standards do not apply to private water
                wells but are useful in assessing water quality. Details on EPA
                drinking water standards are available at:{" "}
                <ExternalLink href="http://www.epa.gov/safewater/mcl.html#mcls">
                  http://www.epa.gov/safewater/mcl.html#mcls
                </ExternalLink>{" "}
                The Texas Commission on Environmental Quality (TCEQ) enforces
                drinking water standards in Texas and has adopted secondary
                standards that in some cases may differ from the EPA secondary
                standards.
                <br />
                Details are available at:{" "}
                <ExternalLink href="http://www.tnrcc.state.tx.us/oprd/rules/pdflib/290_ind.pdf">
                  http://www.tnrcc.state.tx.us/oprd/rules/pdflib/290_ind.pdf
                </ExternalLink>{" "}
              </em>
            </FootnoteTypography>
          </FootnoteGridItem>
        </Grid>
        <div style={{ height: "455px" }} />
        <PageFooter />
      </Page>
    </Root>
  );
};

export default memo(Report);
