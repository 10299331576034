import React, { memo } from "react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { useSignalFireTelemetry } from "./hooks/useSignalFireTelemetry";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import LineChart from "./chartJS/LineChart";
import QuickSetButtons from "./components/QuickSetButtons";
import DateTimeControl from "./components/inputs/DateTimeControl";
import SelectControl from "./components/inputs/SelectControl";
import WellCards from "./components/WellCards";
import OptionsHeader from "./components/OptionsHeader";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);

const SignalFireTelemetry = () => {
  const {
    inputState,
    setInputState,
    handleInputState,
    wellsOptions,
    wellsOptionsIsLoading,
    metaData,
    graphData,
    annotationsGraphData,
    annotationsVisibility,
    toggleAnnotationVisibility,
    isLoading,
    getCSVData,
  } = useSignalFireTelemetry();

  return (
    <>
      <Helmet title="SignalFire Telemetry" />
      <Typography variant="h3" gutterBottom display="inline">
        SignalFire Telemetry
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>SignalFire Telemetry</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper p={4} mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OptionsHeader text="Time Period" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimeControl
              id="start-date"
              label="Start Date"
              value={inputState.startDate}
              onChange={(date) => handleInputState("startDate", date)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimeControl
              id="end-date"
              label="End Date"
              value={inputState.endDate}
              onChange={(date) => handleInputState("endDate", date)}
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <QuickSetButtons setInputState={setInputState} />
          </Grid>
          <Grid item xs={12}>
            <OptionsHeader text="Selection Criteria" />
          </Grid>
          <Grid item xs={12}>
            <SelectControl
              label="Wells"
              id="wells"
              multiple
              value={inputState.wells ?? []}
              onChange={handleInputState}
              options={wellsOptions}
              isLoading={wellsOptionsIsLoading}
              showSelectButtons
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LineChart
            data={graphData}
            isLoading={isLoading}
            yLLabel="Water Level (Feet Below Ground Level)"
            annotations={annotationsGraphData}
            getCSVData={getCSVData}
          />
        </Grid>
        <Grid item xs={12}>
          <WellCards
            getCSVData={getCSVData}
            metaData={metaData}
            toggleAnnotationVisibility={toggleAnnotationVisibility}
            annotationsVisibility={annotationsVisibility}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(SignalFireTelemetry);
