import React, { useRef } from "react";

import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { Box, Paper as MuiPaper } from "@material-ui/core";
import Loader from "../../../../../components/Loader";
import {
  annotatedVerticalLinePlugin,
  chartBackgroundPlugin,
} from "./ChartPlugins";
import annotationPlugin from "chartjs-plugin-annotation";

import ZoomResetButton from "./ZoomResetButton";
import SaveGraphButton from "./SaveGraphButton";
import styled from "styled-components";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useChartOptions } from "./useChartOptions";
import { spacing } from "@material-ui/system";
import ExportCSVButton from "../components/ExportCSVButton";

const Paper = styled(MuiPaper)(spacing);

const GraphWrapper = styled("div")`
  height: 100%;
  overflow-y: auto;
  max-width: 100%;
  width: 100%;
`;

const TimeseriesContainer = styled(Paper)`
  flex-grow: 1;
  min-height: 600px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LineChart = ({
  data,
  error,
  isLoading = true,
  yLLabel = null,
  annotations,
  getCSVData,
}) => {
  const chartRef = useRef(null);
  const options = useChartOptions(yLLabel, annotations);

  if (error) return "An error has occurred: " + error.message;
  return (
    <TimeseriesContainer p={4}>
      {isLoading ? (
        <Loader />
      ) : !data?.datasets?.length ? (
        <Alert mb={4} variant="filled" severity="warning">
          <AlertTitle>No Data Available</AlertTitle>
          Please select a different date range or filters.
        </Alert>
      ) : (
        <>
          <GraphWrapper>
            <Line
              plugins={[
                chartBackgroundPlugin,
                annotatedVerticalLinePlugin,
                annotationPlugin,
              ]}
              ref={chartRef}
              data={data}
              options={options}
              style={{ cursor: "pointer" }}
            />
          </GraphWrapper>
          <Box display="flex">
            <ZoomResetButton resetZoom={() => chartRef?.current?.resetZoom()} />
            <SaveGraphButton
              ref={chartRef}
              title={`SignalFire Telemetry`}
              disabled={isLoading || !data?.datasets?.length}
            />
            <ExportCSVButton
              getCSVData={getCSVData}
              filename="SignalFire Telemetry"
            />
          </Box>
        </>
      )}
    </TimeseriesContainer>
  );
};

export default LineChart;
