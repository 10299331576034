import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import qs from "qs";

export const useAxiosWithAuth = (baseUrl) => {
  const { getAccessTokenSilently } = useAuth0();

  return async (endpoint, params = {}, cancelToken = null) => {
    const token = await getAccessTokenSilently();
    const axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "comma" }),
    });

    const config = {
      params,
    };

    if (cancelToken) {
      config.cancelToken = cancelToken;
    }

    const { data } = await axiosInstance.get(endpoint, config);
    return data;
  };
};
