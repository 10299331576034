import React from "react";
import { FormControl } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { DATE_FORMAT } from "../../constants";

const DateTimeControl = React.memo(({ id, label, value, onChange }) => (
  <FormControl margin="normal" fullWidth>
    <DateTimePicker
      format={DATE_FORMAT}
      inputVariant="outlined"
      autoOk
      id={id}
      label={label}
      value={value}
      onChange={onChange}
    />
  </FormControl>
));

export default DateTimeControl;
