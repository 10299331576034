import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import SelectControl from "../../reports/signalFireTelemetry/components/inputs/SelectControl";
import Button from "@material-ui/core/Button";

const ActionButton = styled(Button)`
  height: 52px;
`;

const ParameterInput = ({
  parameter,
  index,
  updateParameterValue,
  removeParameter,
  options = [],
}) => {
  const isMultiselect = parameter.type === "multiselect";

  const handleValueChange = (id, newValue) => {
    updateParameterValue(index, "value", newValue);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs style={{ margin: "16px 0 8px 0" }}>
        <Typography variant="body1">{parameter.label}</Typography>
      </Grid>
      {isMultiselect ? (
        <Grid item xs={8}>
          <SelectControl
            label="Test Results"
            id={`param-${parameter.key}`}
            multiple
            valueField={parameter.key}
            displayField={parameter.key}
            value={parameter.value}
            onChange={handleValueChange}
            options={options.data}
            isLoading={options.isLoading}
            showSelectButtons
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={3} sm={4} style={{ margin: "16px 0 8px 0" }}>
            <TextField
              type="number"
              variant="outlined"
              value={parameter.min}
              onChange={(e) =>
                updateParameterValue(index, "min", e.target.value)
              }
              placeholder="Min"
              fullWidth
            />
          </Grid>
          <Grid item xs={3} sm={4} style={{ margin: "16px 0 8px 0" }}>
            <TextField
              type="number"
              variant="outlined"
              value={parameter.max}
              onChange={(e) =>
                updateParameterValue(index, "max", e.target.value)
              }
              placeholder="Max"
              fullWidth
            />
          </Grid>
        </>
      )}
      <Grid item>
        <ActionButton
          onClick={() => removeParameter(parameter.key)}
          color="secondary"
          variant="outlined"
          style={{ margin: "16px 0 8px 0" }}
        >
          Remove
        </ActionButton>
      </Grid>
    </Grid>
  );
};

export default ParameterInput;
