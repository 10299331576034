import React from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip as MuiTooltip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const Tooltip = styled(MuiTooltip)(spacing);

const ExportCSVButton = ({ getCSVData, filename, wellNdx = null }) => {
  const handleExport = getCSVData(wellNdx);

  return (
    <Tooltip
      title={`Export ${wellNdx ? "Individual" : "All"} Well Data`}
      ml={2}
    >
      <IconButton
        component={CSVLink}
        data={handleExport}
        filename={`${filename}.csv`}
      >
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ExportCSVButton;
